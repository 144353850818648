import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MaposmService } from 'src/app/services/maposm.service';
import { ExchangesService } from 'src/app/services/exchanges.service';
import { NAOSService } from 'src/app/services/naos.service';
import { AgmMap, MapsAPILoader } from '@agm/core';
import { BagService } from 'src/app/services/bag.service';
import { LogService } from '@dagonmetric/ng-log';

declare var jQuery: any;
declare var $: any;


@Component({
    selector: 'app-mapnaos',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.css'],
    providers: [UserService, MaposmService, ExchangesService, NAOSService, BagService, LogService]
})
export class MapNaosComponent implements OnInit {
    public texto: string = 'Diagonal, Barcelona';
    public lat: any = 41.39697190945534;
    public lng: any = 2.1612395464879275;
    public zoom: number = 15;
    public getAddress: any;
    @ViewChild(AgmMap, {static: true}) public agmMap: AgmMap;
    public loaderSitesNear: boolean;
    public emailValue: string;
    public siteSelected: any;
    public scoreTotal: any;

    public loader: boolean;

    public idclient: any;
    public infoDirection: any;
    public typeId: any;
    public brandId: any;
    public brandString: string;
    public sub: any;

    public direction: any;

    public coordsCurrentClient: any;
    public directionClientNotFound: boolean;
    public directionClient: Array<any>;
    public directionClientSelected: any;

    public sitesNear: Array<any>;
    public siteNearSelected: any;

    public latitudAddress: any;
    public longitudAddress: any;
    public bag: any;
    public arrayProductsFormated: Array<any>;
    public loaderConfirm: boolean;

    public textModal: string;
    public textUrl: string;

    public infoWindowOpened = null;
    public previous_info_window = null;
    public markericon: string;

    public hasSearch: boolean;

    constructor(
        private _routeActivated: ActivatedRoute,
        private _userService: UserService,
        private _exchangeService: ExchangesService,
        private _naosService: NAOSService,
        private _router: Router,
        private _bagService: BagService,
        private readonly logService: LogService,
        private mapsAPILoader: MapsAPILoader
    ) {
        this.sitesNear = [];
        this.directionClientNotFound = true;
        this.directionClient = [];
        this.loader = true;
        this.siteNearSelected = undefined;
        this.bag = [];
        this.arrayProductsFormated = [];
        this.loaderSitesNear = false;
        this.loaderConfirm = false;
        this.markericon = "../../../assets/images/ic_location@2x.png";
        this.hasSearch = false;
    }

    ngOnInit(): void {
        this.logService.trackPageView({
            name: 'Mapa',
            uri: '/home/store/mapnaos'
        });
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        this.emailValue = localStorage.getItem('emailCenter');
        if (localStorage.getItem('BC' + this.idclient)) {
            this.bag = JSON.parse(localStorage.getItem('BC' + this.idclient));
        }
        if (localStorage.getItem('email')) {
            this.emailValue = localStorage.getItem('email');
        }
        this.sub = this._routeActivated.paramMap.subscribe((params: ParamMap) => {
            // id indica el tipo (1 = Farmacia, 2 = Centro de estetica)
            this.typeId = +params.get('id');
            // id2 indica la marca (1 = Bioderma, 2 = Esthederm)
            this.brandId = +params.get('id2');

            if (this.brandId == 1) {
                this.brandString = 'BIODERMA';
            } else if (this.brandId == 2) {
                this.brandString = 'ESTHEDERM';
            }
        });
        this.calcScore();
        this.getCoordsCurrentPosition();
    }

    public getCoordsCurrentPositionButton() {
        this.sitesNear = [];
        this.directionClient = [];
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position) {
                    this.lat = position.coords.latitude;
                    this.lng = position.coords.longitude;
                    this.getPointsNearClient();
                }
            });
        }
    }

    public getCoordsCurrentPosition(): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position) {
                    this.lat = position.coords.latitude;
                    this.lng = position.coords.longitude;
                    this.getPointsNearClient();
                }
            });
        }
    }

    /**
     * Funcion para guardar la direccion seleccionada de las encontradas
     * @param item direccion seleccionada de las encontradas
     */
    public selectDirectionClient(item: any, index: any) {
        let cont: number = 0;
        this.directionClientSelected = item;
        /* this.lat = this.directionClientSelected.geometry.location.lat;
        this.lng = this.directionClientSelected.geometry.location.lng; */
        for (let itemArray of this.directionClient) {
            $('#directionClient' + cont).removeClass('rowDirectionClientSelected');
            cont++;
        }
        $('#directionClient' + index).addClass('rowDirectionClientSelected');
        this.getPointsNearClient();
    }

    /**
     * Funcion para quitar las direcciones que no son de España al buscar
     * @param arrayDirection array de direcciones encontradas
     */
    public formatArrayDirectionClient(arrayDirection: Array<any>) {
        let arrayAux: Array<any> = [];
        for (let item of arrayDirection) {
            if (item.address.country == 'España') {
                arrayAux.push(item);
            }
        }
        this.directionClient = arrayAux;
    }

    /**
     * Funcion para seleccionar el ppvv cercano
     * @param item ppvv cercano seleccionado
     */
    public selectNearDirection(item: any) {
        this.loader = false;
        this.siteNearSelected = item;
        this.formatProductsCenterArray(this.bag);
            let obj = {
                'rewards': this.arrayProductsFormated,
                'where_cd': item.where_cd,
                'ticket_cd': '',
                'exchange_date': this._naosService.getDate()
            };
            this._exchangeService.sendExchange(this.idclient, obj).subscribe(
                () => {
                    this.textModal = 'Tu pedido ha sido realizado con éxito.';
                    this.textUrl = '/home/store/exchange';
                    $('#exampleModalNotificationMap').modal('show');
                    this.bag = [];
                    localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                    localStorage.setItem('refresh', '1');
                    this.loader = true;
                },
                error => {
                    this.loader = true;
                    this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                    this.textUrl = null;
                    $('#exampleModalNotificationMap').modal('show');
                }
            );
    }

    public formatArrayGeocode(respuesta: string): any {
        let respuestaaux = JSON.stringify(respuesta);
        let objresponseFormat: any;
        let index: number = respuestaaux.indexOf(')');
        let textaux = respuestaaux.slice(index + 2);
        objresponseFormat = textaux.slice(0, textaux.length -1);
        return JSON.parse(objresponseFormat);
    }

    /**
     * Funcion para marcar en el mapa la nueva direccion
     */
    public setDirectionMap2(): void {
        this.hasSearch = true;
        this.loader = false;
        if (this.direction !== '' && this.direction !== undefined) {
          if (isNaN(this.direction)) {
            this.mapsAPILoader.load().then(() => {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: this.direction + ', España' }, (results, status) => {
                  if (status === 'OK' && results[0]) {
                    this.directionClient = results;
                    this.directionClientNotFound = true;
                    if(results.length === 1){
                        this.selectDirectionClient(this.directionClient[0], 0);
                    }
                    this.loader = true;
                  } else {
                    this.directionClientNotFound = false;
                    this.directionClient = [];
                    this.sitesNear = [];
                    this.getCoordsCurrentPosition();
                    this.loader = true;
                  }
                });
              });

          } else {
            this.mapsAPILoader.load().then(() => {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: this.direction + ', España'  }, (results, status) => {
                  if (status === 'OK' && results[0]) {
                    this.directionClient = results;
                      this.directionClientNotFound = true;
                      if(results.length === 1){
                        this.selectDirectionClient(this.directionClient[0], 0);
                      }
                    this.loader = true;
                  } else {
                    this.directionClientNotFound = false;
                    this.directionClient = [];
                    this.sitesNear = [];
                    this.getCoordsCurrentPosition();
                    this.loader = true;
                  }
                });
              });
          }
        } else {
            this.textModal = 'No has introducido ninguna dirección.';
            this.textUrl = null;
            $('#exampleModalNotificationMap').modal('show');
            this.loader = true;
        }
    }

    public setDirectionMap(): void {
        this.hasSearch = true;
        this.loader = false;
        if (this.direction !== '' && this.direction !== undefined) {
          if (isNaN(this.direction)) {
            this.mapsAPILoader.load().then(() => {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: this.direction + ', España' }, (results, status) => {
                  if (status === 'OK' && results[0]) {
                    this.lat = results[0].geometry.location.lat();
                    this.lng = results[0].geometry.location.lng();
                    this.directionClientNotFound = true;
                    this.directionClient = results;
                    if(results.length === 1){
                        this.selectDirectionClient(this.directionClient[0], 0);
                    }
                    this.zoom = 15
                    this.loader = true;
                    
                  } else {
                    this.directionClientNotFound = false;
                    this.directionClient = [];
                    this.sitesNear = [];
                    this.getCoordsCurrentPosition();
                    this.loader = true;
                  }
                });
              });
          } else {
            this.mapsAPILoader.load().then(() => {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: this.direction + ', España'  }, (results, status) => {
                  if (status === 'OK' && results[0]) {
                    this.lat = results[0].geometry.location.lat();
                    this.lng = results[0].geometry.location.lng();
                    this.directionClientNotFound = true;
                    this.directionClient = results;
                    if(results.length === 1){
                        this.selectDirectionClient(this.directionClient[0], 0);
                    }
                    this.zoom = 15
                    this.loader = true;
                  } else {
                    let respuestaaux = "Error del geocoder"
                    let objresponseFormat: any;
                    let index: number = respuestaaux.indexOf(')');
                    let textaux = respuestaaux.slice(index + 2);
                    objresponseFormat = textaux.slice(0, textaux.length - 1);
                    let cadenasinultimocaracter = objresponseFormat.substring(0, objresponseFormat.length - 1);
                    let cadenasinprimercaracter = cadenasinultimocaracter.substring(1);
                    let formatjson = JSON.parse(cadenasinprimercaracter)
                    if (formatjson.results.length == 0) {
                      this.directionClientNotFound = false;
                      this.directionClient = [];
                      this.sitesNear = [];
                      this.getCoordsCurrentPosition();
                     } else {
                      this.directionClient = formatjson.results;
                      this.directionClientNotFound = true;
                      if(formatjson.results.length === 1){
                        this.selectDirectionClient(this.directionClient[0], 0);
                      }
                     }
                  this.loader = true;
                  }
                });
              });
          }
        } else {
            this.textModal = 'No has introducido ninguna dirección.';
            this.textUrl = null;
            $('#exampleModalNotificationMap').modal('show');
            this.loader = true;
        }
    }

    /**
     * Funcion para mostrar solo una vez el campo si la localidad y la provincia vienen iguales
     */
    public checkLocalityProvince(locality: string, province: string): string {
        if (locality === province) {
            return locality;
        } else {
            return locality + ', ' + province;
        }
    }

    /**
     * Funcion para obtener los ppvv cercanos al cliente
     */
    public getPointsNearClient(): void {
        this.loaderSitesNear = true;
        this._userService.getPointsNearClient(this.brandString, this.lat, this.lng).subscribe(
            response => {
                this.sitesNear = response;
                let auxArray: Array<any> = [];
                for (let item of this.sitesNear) {
                    if (item.jwhere.allowExchanges == 'true' || item.jwhere.allowExchanges == true) {
                        auxArray.push(item);
                    }
                }
                this.sitesNear = auxArray;
                this.loaderSitesNear = false;
            },
            () => {
                this.loaderSitesNear = false;
                console.error('Error al encontrar los sitios cercanos.');
            }
        );
    }

    /**
     * Funcion para calcular el total de estrellas en la cesta
     */
    public calcScore(): void {
        let resultado: number = 0;
        for (let item of this.bag) {
            if (item.jwhat.payments.loyalty_units[0].loyalty_unit_id !== 7) {
                resultado = resultado + 0;
            } else {
                resultado = resultado + (item.jwhat.payments.loyalty_units[0].loyalty_unit_quantity * item.quantity);
            }
        }
        this.scoreTotal = resultado.toString();
    }

    public sendExchangeProduct(item: any): void {
        this.formatProductsCenterArray(this.bag);
        this.siteSelected = item;
        localStorage.removeItem('emailCenter');
        $('#modalCanjeProductos').modal('show');
        localStorage.setItem('refresh', '1');
    }

    public formatCodeShopToSend(idcenter: any): string {
        for (let item of this.sitesNear) {
            if (item.where_id == idcenter) {
                return item.where_cd;
            }
        }
    }

    /**
     * Funcion para formatear los productos para enviar a centro
     * @param arrayProducts array de productos de la cesta
     */
    public formatProductsCenterArray(arrayProducts: Array<any>): void {
        this.arrayProductsFormated = [];
        if (arrayProducts.length > 0) {
            for (let item of arrayProducts) {
                let obj = {
                    'reward_quantity': item.quantity,
                    'what_cd': item.what_cd,
                    'json_type_ld': 'JREWARDS_PF',
                    'management': {
                        'email': this.emailValue
                    }
                };
                this.arrayProductsFormated.push(obj);
            }
        }
    }

    public exchangeProductsCenter(): void {
        this.loaderSitesNear = true;
        let obj: any;
        this.loaderConfirm = true;
        obj = {
            'rewards': this.arrayProductsFormated,
            'where_cd':  this.siteSelected.where_cd,
            'ticket_cd': '',
            'exchange_date': this._naosService.getDate()
        };

        this._exchangeService.sendExchange(this.idclient, obj).subscribe(
            () => {
                this.loaderConfirm = false;
                $('#modalCanjeProductos').modal('hide');
                this.textUrl = '/home/store/exchange';
                this.textModal = 'Tu pedido ha sido realizado con éxito.';
                $('#exampleModalNotificationMap').modal('show');
                this.bag = [];
                localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                localStorage.setItem('refresh', '1');
                this.loaderSitesNear = false;
            },
            error => {
                this.loaderSitesNear = false;
                this.loaderConfirm = false;
                localStorage.setItem('refresh', '1');
                $('#modalCanjeProductos').modal('hide');
                this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                this.textUrl = null;
                $('#exampleModalNotificationMap').modal('show');
            }
        );
        localStorage.removeItem('email');
    }

    /**
     * Metodo para coger solamente las unidades de fidelización con id 7
     * @param arrayUnit array de unidades de fidelización del producto
     */
    public showLoyaltyUnitPrize(arrayUnit: Array<any>): number {
      let prize: number = 0;
      let contNotFound: number = 0;
      for (let item of arrayUnit) {
        if (item.loyalty_unit_id == 7) {
          contNotFound++;
          prize = item.loyalty_unit_quantity;
        }
      }
      if (contNotFound > 0) {
        return prize;
      } else {
        return 0;
      }
    }

    public closeWindow() {
        if (this.previous_info_window != null ) {
          this.previous_info_window.close();
          }
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            /* this._router.navigate([this.textUrl]); */
            this._router.navigateByUrl('/refresh', {skipLocationChange: true}).then(()=> this._router.navigate([this.textUrl]));
        }
    }

    public selectMarker(infoWindow) {
        if (this.previous_info_window == null) {
            this.previous_info_window = infoWindow;
        } else {
         this.infoWindowOpened = infoWindow;
         this.previous_info_window.close();
        }
        this.previous_info_window = infoWindow;
       }
}
