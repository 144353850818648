import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RegisterComponent } from './register.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';
import { SelectSitesModule } from 'src/app/components/select-sites/selectsites.module';

@NgModule({
    declarations: [RegisterComponent],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        TooltipModule,
        NgbModule,
        NaosNotificationModule,
        SelectSitesModule
    ],
    exports: [RegisterComponent],
    providers: [],
})
export class RegisterModule {}
