import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromotionsComponent } from './promotions.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';
import { SelectSitesModule } from 'src/app/components/select-sites/selectsites.module';

@NgModule({
  declarations: [PromotionsComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    TooltipModule,
    NgbModule,
    NaosNotificationModule,
    SelectSitesModule
  ],
  exports: [PromotionsComponent],
  providers: [],
})
export class PromotionsModule {}
