import { Component, OnInit, HostListener } from '@angular/core';
import { RegisterService } from 'src/app/services/register.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';
import { DirectionService } from 'src/app/services/direction.service';
import { AppSettings } from 'src/app/constants.component';
import { environment } from 'src/environments/environment';
import { LogService } from '@dagonmetric/ng-log';
import { NGXLogger } from 'ngx-logger';
import { LogsTrackService } from 'src/app/services/logs.service';
import { JsonPipe } from '@angular/common';
declare let gtag: Function;
/* declare var gtag_report_conversion: Function; */
declare var $: any;
declare var require: any;
var unorm = require('unorm');

@Component({
    selector: 'app-naos-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css'],
    providers: [RegisterService, NAOSService, DirectionService, LogService, LogsTrackService]
})

export class RegisterComponent implements OnInit {

    public name: string;
    public surname: string;
    public email: string;
    public password: string;
    public repeatpassword: string;
    public cp: string;
    public ppvv: any;
    public valuePronvinceImportant: string;
    public puntoventa1: any;
    public puntoventa2: any;
    public enrolador: string;
    public checkLegal: boolean;
    public coms: boolean;
    public friendscode: string;

    public listProvinces: Array<any>;
    public stringNamePharmacy: string;
    public stringNameEnrolador: string;
    public showInputSite: boolean;
    public showInputEnrolador: boolean;

    public puntosVenta: Array<any>;
    public enroladores: Array<any>;

    public idclient: string;

    public loader: boolean;
    public showpassword: boolean;
    public showpasswordrepeat: boolean;
    public key: any;

    public textModal: string;
    public textUrl: string;
    public link: boolean;

    public loaderSelectEnrolador: boolean;
    public loaderSelectPPVV: boolean;
    public pageSites: number;
    public buttonSendDisabled: boolean;

    public showNotificationMsg: boolean;
    public showNotificationMsgType: boolean;

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.key = event.key;
        if (this.key === 'Enter') {
            this.sendRegister();
        }
    }

    constructor(
        private _registerService: RegisterService,
        private _directionService: DirectionService,
        private _router: Router,
        public _NAOSService: NAOSService,
        private readonly logService: LogService,
        private logger: NGXLogger,
        private _logTrackService: LogsTrackService
    ) {
        this.checkLegal = false;
        this.coms = false;
        this.puntosVenta = [];
        this.enroladores = [];
        this.enrolador = '';
        this.puntoventa1 = '';
        this.puntoventa2 = '';
        this.showpassword = false;
        this.showpasswordrepeat = false;
        this.loader = false;
        $('[data-toggle=popover]').popover();
        this.link = false;
        this.showInputSite = true;
        this.showInputEnrolador = true;
        this.loaderSelectPPVV = false;
        this.loaderSelectEnrolador = false;
        this.pageSites = 0;
        this.listProvinces = this.orderProvinces(AppSettings.provincias.data.provinces);
        this.buttonSendDisabled = false;
        this.friendscode = undefined;

        this.showNotificationMsg = false;
        this.showNotificationMsgType = false;
        this.stringNamePharmacy = ' ';
        this.valuePronvinceImportant = ' ';
        this.stringNameEnrolador = ' ';
    }

    ngOnInit(): void {
        this.logService.trackPageView({
            name: 'Register',
            uri: '/register'
        });
        this.loader = true;
        this.initRegisterObjetc();

        if (this.getParameterByName('ppvv')){
            var ppvvId = this.getParameterByName('ppvv');
            this.AddDropdownPharmacy(ppvvId);
        }

        $(document).ready(function() {
            $("#show_hide_password2 a").on('click', function(event) {
                event.preventDefault();
                if($('#show_hide_password2 input').attr("type") == "text"){
                    $('#show_hide_password2 input').attr('type', 'password');
                    $('#show_hide_password2 i').addClass( "fa-eye-slash" );
                    $('#show_hide_password2 i').removeClass( "fa-eye" );
                }else if($('#show_hide_password2 input').attr("type") == "password"){
                    $('#show_hide_password2 input').attr('type', 'text');
                    $('#show_hide_password2 i').removeClass( "fa-eye-slash" );
                    $('#show_hide_password2 i').addClass( "fa-eye" );
                }
            });
        });
        $(document).ready(function() {
            $("#show_hide_password a").on('click', function(event) {
                event.preventDefault();
                if($('#show_hide_password input').attr("type") == "text"){
                    $('#show_hide_password input').attr('type', 'password');
                    $('#show_hide_password i').addClass( "fa-eye-slash" );
                    $('#show_hide_password i').removeClass( "fa-eye" );
                }else if($('#show_hide_password input').attr("type") == "password"){
                    $('#show_hide_password input').attr('type', 'text');
                    $('#show_hide_password i').removeClass( "fa-eye-slash" );
                    $('#show_hide_password i').addClass( "fa-eye" );
                }
            });
        });
    }

    /**
     * Funcion para extraer parametros de la url
     */
    public getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    public AddDropdownPharmacy(ppvvcode: string){
        this._NAOSService.getPuntosVentaOnlineByCode(ppvvcode).subscribe(
            response => {
                if (response.length > 0) {
                    var pharmacyItem = response[0];

                    // Format full name pharmacy
                    if (pharmacyItem.jwhereAddress && pharmacyItem.jwhereAddress.postal_cd !== '') {
                        pharmacyItem.fullName = pharmacyItem.jwhere.where_ld.toUpperCase() + ' (' + pharmacyItem.where_cd + ' - ' + pharmacyItem.jwhereAddress.postal_cd + ')';
                    } else {
                        pharmacyItem.fullName = pharmacyItem.jwhere.where_ld.toUpperCase() + ' (' + pharmacyItem.where_cd + ')';
                    }

                    // Update province pharmacy
                    var ppvvProvince = this.listProvinces.find(element => element.cdprovincia === pharmacyItem.jwhereAddress.postal_cd.substring(0,2));
                    if(ppvvProvince){
                        this.valuePronvinceImportant = ppvvProvince.cdprovincia;
                        this.showInputSite = true;
                    } else {
                        this.valuePronvinceImportant = "XX";
                        this.showInputSite = true;
                    }

                    // put value only left call services to get the pharmacy
                    this.stringNamePharmacy = pharmacyItem.fullName;

                    // Open dropdown agent
                    /* this.getEnroladorQR(ppvvcode); */
                } else {
                    console.error('Error al obtener los puntos de venta, codigo erroneo.');
                }
            },
            error => {
                console.error('Error al obtener los puntos de venta.');
            }
        );
    }

    /**
     * Funcion para ordenar las provincias
     * @param arrayProvincias array de provincias
     */
    public orderProvinces(arrayProvincias: Array<any>): Array<any> {
        let arrayOrder: Array<any> = [];
        arrayOrder = arrayProvincias.sort(function (a, b) {
            if (unorm.nfc(a.dcprovincia).replace(/[\u0300-\u036f]/g, "") > unorm.nfc(b.dcprovincia).replace(/[\u0300-\u036f]/g, "")) {
              return 1;
            }
            if (unorm.nfc(a.dcprovincia).replace(/[\u0300-\u036f]/g, "") < unorm.nfc(b.dcprovincia).replace(/[\u0300-\u036f]/g, "")) {
              return -1;
            }
            return 0;
          });
        return arrayOrder;
    }

    public initRegisterObjetc(): void {
        if (localStorage.getItem('objregister')) {
            let objRegister = JSON.parse(localStorage.getItem('objregister'));
            this.name = objRegister.firstname;
            this.email = objRegister.email;
            this.password = objRegister.password;
            this.cp = objRegister.cp;
            this.valuePronvinceImportant = objRegister.province;
            this.stringNamePharmacy = objRegister.whereid_1;
            this.puntoventa1 = objRegister.whereid_1;
            this.puntoventa2 = objRegister.whereid_2;
            this.stringNameEnrolador = objRegister.employee_identifier;
            this.checkLegal = objRegister.legal;
            this.coms = objRegister.coms;
            this.friendscode = objRegister.friendscode;
            this.enrolador = objRegister.employee_identifier;
            if (objRegister.employee_identifier !== '') {
                this.showInputEnrolador = true;
            }
            if (objRegister.whereid_1 !== '') {
                this.showInputSite = true;
                this.stringNamePharmacy = objRegister.namesite;
            }
            if (objRegister.whereid_2 !== '') {
                this.showInputSite = true;
                this.stringNamePharmacy = objRegister.namesite;
            }
        } else {

        }
    }

    public checkPassword(password1: string, password2: string): boolean {
        if (password1 === password2) {
            return true;
        } else {
            return false;
        }
    }

    public saveDataObject(): void {
        let obj = {
            'employee_identifier': this.stringNameEnrolador,
            'firstname': this.name,
            'surname': this.surname,
            'password': this.password,
            'passwordrepeat': this.repeatpassword,
            'legal': this.checkLegal,
            'coms': this.coms,
            'email': this.email,
            'companyId': 4,
            'whereid_1': this.puntoventa1,
            'whereid_2': this.puntoventa2,
            'cp': this.cp,
            'province': this.valuePronvinceImportant,
            'namesite': this.stringNamePharmacy,
            'friendscode': this.friendscode
        };
        localStorage.setItem('objregister', JSON.stringify(obj));
    }

    public closedInputSearchPharmacy(): void {
        document.getElementById('myDropdownPharmacy').classList.toggle('hide');
        document.getElementById('myDropdownPharmacyMobile').classList.toggle('hide');
        this.enrolador = '';
        this.stringNamePharmacy = '';
        this.stringNameEnrolador = '';
        this.showInputSite = true;
        this.showInputEnrolador = false;
    }

    /* public closedInputEnroladores(): void {
        document.getElementById('myDropdownEnrolador').classList.toggle('hide');
        this.stringNameEnrolador = '';
        this.showInputEnrolador = true;
    } */

    public selectSitesPharmacy(): void {
        let ppvvonline: Array<any> = [];
        let ppvvmixtos: Array<any> = [];
        let ppvvonline2: Array<any> = [];
        if (this.valuePronvinceImportant !== '') {
            this.loaderSelectPPVV = true;
            this.stringNamePharmacy = '';
            this.stringNameEnrolador = '';
            this.showInputEnrolador = false;
            this.enroladores = [];
            this.puntosVenta = [];
            if (this.valuePronvinceImportant === 'XX') {
                this._NAOSService.getPuntosVentaOnline().subscribe(
                    response => {
                        if (response.length > 0) {
                            ppvvonline = response;
                        }
                        this._NAOSService.getPuntosVentaOnlineMixtos().subscribe(
                            response => {
                                if (response.length > 0) {
                                    ppvvmixtos = response;
                                    this._NAOSService.getPuntosVentaPruebaPPVVLISTA().subscribe(
                                        responseOnline => {
                                            ppvvonline2 = responseOnline;
                                            this.puntosVenta = ppvvonline.concat(ppvvmixtos).concat(ppvvonline2);
                                            this.formatLabelSelectCentersPharmarcy(this.puntosVenta);
                                            this.loaderSelectPPVV = false;
                                        },
                                        error => {
                                            console.error('ERROR' + JSON.stringify(error));
                                        }
                                    );
                                }
                            },
                            error => {
                                this.loaderSelectPPVV = false;
                                console.error('Error al obtener los puntos de venta.');
                            }
                        );
                    },
                    error => {
                        this.loaderSelectPPVV = false;
                        console.error('Error al obtener los puntos de venta.');
                    }
                );
            } else {
                this._NAOSService.getShopSiteProvinceWithoutType(this.valuePronvinceImportant).subscribe(
                    response => {
                        if (response.length > 0) {
                            this.puntosVenta = response;
                            this.formatLabelSelectCentersPharmarcy(this.puntosVenta);
                        }
                        this.loaderSelectPPVV = false;
                    },
                    () => {
                        this.loaderSelectPPVV = false;
                        console.error('Error al obtener los puntos de venta.');
                    }
                );
            }
        } else {
            this.stringNamePharmacy = '';
            this.stringNameEnrolador = '';
            this.showInputEnrolador = false;
            this.enroladores = [];
            this.puntosVenta = [];
        }
        this.showInputEnrolador = true;
    }

    public formatLabelSelectCentersPharmarcy(arraySites: any): any {
        let auxArray: Array<any> = [];
        for (let item of arraySites) {
            if (item.jwhereAddress && item.jwhereAddress.postal_cd !== '') {
                item.fullName = item.jwhere.where_ld.toUpperCase() + ' (' + item.where_cd + ' - ' + item.jwhereAddress.postal_cd + ')';
            } else {
                item.fullName = item.jwhere.where_ld.toUpperCase() + ' (' + item.where_cd + ')';
            }
            auxArray.push(item);
        }
        $('#myDropdownPharmacy').addClass('show');
        $('#myDropdownPharmacyMobile').addClass('show');
        return auxArray;
    }

    /* public getSiteSelectedPharmacy(evento: any): void {
        this.getEnrolador(evento);
    } */

    public setSelectedSitePharmacy(sitewhere_id: any, name: string): void {
        this.stringNamePharmacy = name;
        this.stringNameEnrolador = '';
        this.showInputSite = true;

        console.log(sitewhere_id)
        this._NAOSService.getShopDetailById(sitewhere_id).subscribe(
            response => {
                if (response[0].jwhere.shop_type_id !== 2) {
                    this.puntoventa1 = sitewhere_id;
                    this.puntoventa2 = '';
                } else if (response[0].jwhere.shop_type_id == 2) {
                    this.puntoventa1 = '';
                    this.puntoventa2 = sitewhere_id;
                }
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );



        document.getElementById('myDropdownPharmacy').classList.toggle('hide');
        document.getElementById('myDropdownPharmacyMobile').classList.toggle('hide');
        /* this.getSiteSelectedPharmacy(sitewhere_id); */
        this.showInputEnrolador = true;
    }

    /* public setSelectedSiteEnrolador(value_code: any, name: string, surname: string, code: string): void {
        if (surname !== 'null') {
            this.stringNameEnrolador =  code;
        } else {
            this.stringNameEnrolador = code;
        }
        this.enrolador = value_code;
        this.showInputEnrolador = true;
        document.getElementById('myDropdownEnrolador').classList.toggle('hide');
    } */

    public changeInputForSelect(): void {
        this.showInputSite = false;
        setTimeout(() => {
            document.getElementById('myDropdownPharmacy').classList.toggle('show');
            document.getElementById('myDropdownPharmacyMobile').classList.toggle('show');
        }, 500);
        this.formatLabelSelectCentersPharmarcy(this.puntosVenta);
    }

    /* public changeInputForSelectEnrolador(): void {
        this.showInputEnrolador = false;
        setTimeout(() => {
            document.getElementById('myDropdownEnrolador').classList.toggle('show');
        }, 500);
    } */

    public filterFunctionPharmacy() {
        let input, filter, ul, li, a, i, div;
        input = document.getElementById('myInputPharmacy');
        filter = input.value.toUpperCase();
        div = document.getElementById('myDropdownPharmacy');
        a = div.getElementsByTagName('a');
        for (i = 0; i < a.length; i++) {
        let txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = '';
        } else {
            a[i].style.display = 'none';
        }
        }
    }

    public filterFunctionPharmacyMobile() {
        let input, filter, ul, li, a, i, div;
        input = document.getElementById('myInputPharmacyMobile');
        filter = input.value.toUpperCase();
        div = document.getElementById('myDropdownPharmacyMobile');
        a = div.getElementsByTagName('a');
        for (i = 0; i < a.length; i++) {
        let txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = '';
        } else {
            a[i].style.display = 'none';
        }
        }
    }

    /* public filterFunctionenrolador() {
        let input, filter, ul, li, a, i, div;
        input = document.getElementById('myInputenrolador');
        filter = input.value.toUpperCase();
        div = document.getElementById('myDropdownEnrolador');
        a = div.getElementsByTagName('a');
        for (i = 0; i < a.length; i++) {
        let txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = '';
        } else {
            a[i].style.display = 'none';
        }
        }
    } */

    /**
     * Funcion para ver la contraseña
     * @param value valor del checkbox
     */
    public showPassword() {
        if (!this.showpassword) {
            this.showpassword = true;
        } else {
            this.showpassword = false;
        }
    }

    public showPasswordRepeat(): void {
        if (!this.showpasswordrepeat) {
            this.showpasswordrepeat = true;
        } else {
            this.showpasswordrepeat = false;
        }
    }

    /**
     * Funcion para redireccionar al login
     */
    public goToLogin(): void {
        this._router.navigate(['/login']);
    }

    public goToCCGG(): void {
        this._router.navigate(['/legals']);
    }

    public goToPrivacity(): void {
        this._router.navigate(['/privacitynew']);
    }

    public validate30Surname(surname: string): void {
      if (surname !== undefined && surname !== '' && surname !== null) {
        if (surname.length >= 30) {
          let auxstring = surname.substr(0, 29);
          this.surname = auxstring;
      }
      }
    }

    public validate30Name(name: string): void {
        if (name.length >= 30) {
            let auxstring = name.substr(0, 29);
            this.name = auxstring;
        }
    }

    /**
     * Hacer la petición de registro
     */
    public sendRegister(): void {
        this.buttonSendDisabled = true;
        if (this.checkFormulario() && this.checkLegal) {
            this.showNotificationMsg = false;
            if (this.validateName()) {
                if (this.validateSurname()) {
                    this.showNotificationMsg = false;
                    if (this.validateEmail()) {
                        this.showNotificationMsg = false;
                        if (this.checkCP()) {
                            this.showNotificationMsg = false;
                            if (this.checkCPSpain()) {
                                this.showNotificationMsg = false;
                                if (this.checkPassword(this.password, this.repeatpassword)) {
                                    this.showNotificationMsg = false;
                                    if (this.checkValidationPassword(this.password)) {
                                        if (this.friendscode !== undefined && this.friendscode !== '') {
                                            this._registerService.validateFriendsCode(this.friendscode).subscribe(
                                                response => {
                                                    this.showNotificationMsg = false;
                                                    this.loader = false;
                                                    let obj = {
                                                        'firstname': this.name,
                                                        'surname': this.surname,
                                                        'password': this.password,
                                                        'legal': this.formatLegal(this.checkLegal),
                                                        'coms': this.coms,
                                                        'email': this.email,
                                                        'companyId': 4,
                                                        'whereid_1': this.puntoventa1,
                                                        'whereid_2': this.puntoventa2,
                                                        'parent_code': this.friendscode
                                                    };
                                                    this._registerService.sendRegister(obj).subscribe(
                                                        response => {
                                                            localStorage.removeItem('objregister');
                                                            this.idclient = response.customerId;

                                                            let objDirection = {
                                                                'postalCode': this.cp,
                                                                'number': '0',
                                                                'addressType': '',
                                                                'localty': 'NAOSGENERICLOCALTY',
                                                                'alias': '',
                                                                'mainAddress': true,
                                                                'stairs': '',
                                                                'door': '',
                                                                'generic': true,
                                                                'portal': '',
                                                                'floor': '',
                                                                'country': 'ESPAÑA',
                                                                'addressName': 'NAOSGENERICSTRRET',
                                                                'province': 'NAOSGENERICPROVINCE'
                                                            };
                                                            this._directionService.sendDirectionClientPublic(this.idclient, objDirection).subscribe(
                                                                () => {
                                                                    localStorage.removeItem('objregister');
                                                                    this.loader = true;
                                                                    this.buttonSendDisabled = false;
                                                                    this.textModal = 'Tu cuenta ha sido confirmada, puedes iniciar sesión con el botón "Entrar" introduciendo tu email y contraseña.';
                                                                    this.showNotificationMsg = true;
                                                                    this.textUrl = '/login';
                                                                    this.showNotificationMsgType = true;
                                                                    /* gtag_report_conversion(this._router.url); */
                                                                    $('#exampleModalNotificationRegister').modal('show');
                                                                },
                                                                error => {
                                                                    console.log(error)
                                                                    console.error('Error ' + JSON.stringify(error));
                                                                    this.loader = true;
                                                                    this.buttonSendDisabled = false;
                                                                }
                                                            );
                                                        },
                                                        error => {
                                                            console.error(JSON.stringify(error));
                                                            this.loader = true;
                                                            this.buttonSendDisabled = false;
                                                            console.log(error)
                                                            if (error.error.code == '409.24') {
                                                                this.textModal = 'No ha sido posible realizar el registro. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.\n Recuerda que si ya te has registrado previamente debes Iniciar Sesión para acceder a NAOS Club';
                                                                localStorage.removeItem('objregister');
                                                                this.showNotificationMsg = true;
                                                                this.showNotificationMsgType = false;
                                                                this.textUrl = '/login';
                                                                $('#exampleModalNotificationRegisterRepeatEmail').modal('show');
                                                            } else {
                                                                this.textModal = 'No ha sido posible realizar el registro. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                                                                this.textUrl = null;
                                                                this.showNotificationMsg = true;
                                                                this.showNotificationMsgType = false;
                                                                $('#exampleModalNotificationRegister').modal('show');
                                                            }
                                                        }
                                                    );
                                                        },
                                                        error => {
                                                            this.textModal = 'Este código no es válido. Comprueba el código de tu amig@.';
                                                            localStorage.removeItem('objregister');
                                                            this.showNotificationMsg = true;
                                                            this.showNotificationMsgType = false;
                                                            this.buttonSendDisabled = false;
                                                            $('#exampleModalNotificationRegister').modal('show');
                                                        }
                                                    );
                                        } else {
                                            this.showNotificationMsg = false;
                                            this.loader = false;
                                            let obj = {
                                                'firstname': this.name,
                                                'surname': this.surname,
                                                'password': this.password,
                                                'legal': this.formatLegal(this.checkLegal),
                                                'coms': this.coms,
                                                'email': this.email,
                                                'companyId': 4,
                                                'whereid_1': this.puntoventa1,
                                                'whereid_2': this.puntoventa2
                                            };
                                            this._registerService.sendRegister(obj).subscribe(
                                                response => {
                                                    localStorage.removeItem('objregister');
                                                    this.idclient = response.customerId;

                                                    let objDirection = {
                                                        'postalCode': this.cp,
                                                        'number': '0',
                                                        'addressType': '',
                                                        'localty': 'NAOSGENERICLOCALTY',
                                                        'alias': '',
                                                        'mainAddress': true,
                                                        'stairs': '',
                                                        'door': '',
                                                        'generic': true,
                                                        'portal': '',
                                                        'floor': '',
                                                        'country': 'ESPAÑA',
                                                        'addressName': 'NAOSGENERICSTRRET',
                                                        'province': 'NAOSGENERICPROVINCE'
                                                    };
                                                    this._directionService.sendDirectionClientPublic(this.idclient, objDirection).subscribe(
                                                        () => {
                                                            localStorage.removeItem('objregister');
                                                            this.loader = true;
                                                            this.buttonSendDisabled = false;
                                                            this.textModal = 'Tu cuenta ha sido confirmada, puedes iniciar sesión con el botón "Entrar" introduciendo tu email y contraseña.';
                                                            this.showNotificationMsg = true;
                                                            this.textUrl = '/login';
                                                            this.showNotificationMsgType = true;
                                                            /* gtag_report_conversion(this._router.url); */
                                                            $('#exampleModalNotificationRegister').modal('show');
                                                        },
                                                        error => {
                                                            console.error('Error ' + JSON.stringify(error));
                                                            this.loader = true;
                                                            this.buttonSendDisabled = false;
                                                        }
                                                    );
                                                },
                                                error => {
                                                    console.error(+JSON.stringify(error));
                                                    this.loader = true;
                                                    this.buttonSendDisabled = false;
                                                    if (error.error.code == '409.24') {
                                                        this.textModal = 'No ha sido posible realizar el registro. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.\n Recuerda que si ya te has registrado previamente debes Iniciar Sesión para acceder a NAOS Club.';
                                                        localStorage.removeItem('objregister');
                                                        this.showNotificationMsg = true;
                                                        this.showNotificationMsgType = false;
                                                        this.textUrl = '/login';
                                                        $('#exampleModalNotificationRegisterRepeatEmail').modal('show');
                                                    } else {
                                                        this.textModal = 'No ha sido posible realizar el registro. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                                                        this.textUrl = null;
                                                        this.showNotificationMsg = true;
                                                        this.showNotificationMsgType = false;
                                                        $('#exampleModalNotificationRegister').modal('show');
                                                    }
                                                }
                                            );
                                        }
                                    } else {
                                        this.buttonSendDisabled = false;
                                        this.textModal = 'La contraseña debe tener una longitud de entre 8 y 20 caracteres y contener mayúsculas, minúsculas y números.';
                                        this.textUrl = null;
                                        this.showNotificationMsg = true;
                                        this.showNotificationMsgType = false;
                                        $('#exampleModalNotificationRegister').modal('show');
                                    }
                                } else {
                                    this.buttonSendDisabled = false;
                                    this.textModal = 'Las contraseñas no coinciden.';
                                    this.textUrl = null;
                                    this.showNotificationMsg = true;
                                    this.showNotificationMsgType = false;
                                    $('#exampleModalNotificationRegister').modal('show');
                                }
                            } else {
                                this.buttonSendDisabled = false;
                                this.textModal = 'Lo sentimos, pero únicamente se pueden dar de alta en el club los residentes en Península, Baleares y Canarias';
                                this.textUrl = null;
                                this.showNotificationMsg = true;
                                this.showNotificationMsgType = false;
                                $('#exampleModalNotificationRegister').modal('show');
                            }
                        } else {
                            this.buttonSendDisabled = false;
                            this.textModal = 'El código postal es incorrecto. Por favor, introduce uno válido.';
                            this.textUrl = null;
                            this.showNotificationMsg = true;
                            this.showNotificationMsgType = false;
                            $('#exampleModalNotificationRegister').modal('show');
                        }
                    } else {
                        this.buttonSendDisabled = false;
                        this.textModal = 'Por favor, introduce un email válido.';
                        this.textUrl = null;
                        this.showNotificationMsg = true;
                        this.showNotificationMsgType = false;
                        $('#exampleModalNotificationRegister').modal('show');
                    }
                } else {
                    this.buttonSendDisabled = false;
                    this.textModal = 'El campo apellidos no es válido.';
                    this.textUrl = null;
                    this.showNotificationMsg = true;
                    this.showNotificationMsgType = false;
                    $('#exampleModalNotificationRegister').modal('show');
                }
            } else {
                this.buttonSendDisabled = false;
                this.textModal = 'El nombre no puede contener números. Por favor, introduce un nombre válido.';
                this.textUrl = null;
                this.showNotificationMsg = true;
                this.showNotificationMsgType = false;
                $('#exampleModalNotificationRegister').modal('show');
            }
        } else {
            this.buttonSendDisabled = false;
            this.textModal = 'Por favor, rellena los campos obligatorios y acepta nuestras condiciones para continuar.';
            this.textUrl = null;
            this.showNotificationMsg = true;
            this.showNotificationMsgType = false;
            $('#exampleModalNotificationRegister').modal('show');
        }
    }

    public getSiteSelected(evento: any): void {
        this.ppvv = evento;
        this.getEnrolador(this.ppvv);
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }

    /**
     * Funcion para pedir lo enroladores por qr
     * @param idppvv
     */
    public getEnroladorQR(idppvv: any): void {
        this.enroladores = [];
        this.loaderSelectEnrolador = true;
        if (idppvv !== 0) {
            this._NAOSService.getShopDetailByWhereCd(idppvv).subscribe(
                response => {
                    if (response[0].jwhere.shop_type_id !== 2) {
                        this.puntoventa1 = idppvv;
                        this.puntoventa2 = '';
                    } else if (response[0].jwhere.shop_type_id == 2) {
                        this.puntoventa1 = '';
                        this.puntoventa2 = idppvv;
                    }
                    this._NAOSService.getEnroladres(response[0].where_id).subscribe(
                        responseEnroladores => {
                            for (let item of responseEnroladores) {
                                if (item.employeeTypeSd.en !== 'Delegate' && item.employeeTypeSd.en !== 'Trainer') {
                                    this.enroladores.push(item);
                                }
                            }
                            if (this.enroladores.length > 0) {
                                this.showInputEnrolador = true;
                            } else {
                                this.showInputEnrolador = false;
                            }
                            this.loaderSelectEnrolador = false;
                        },
                        () => {
                            this.loaderSelectEnrolador = false;
                            console.error('Error al obtener los puntos de venta.');
                        }
                    );
                },
                error => {
                    console.error('Error ' + JSON.stringify(error));
                }
            );
        } else {
            this.loaderSelectEnrolador = false;
        }

    }

    /**
     * Funcion para pedir lo enroladores normal
     * @param idppvv
     */
    public getEnrolador(idppvv: any): void {
      this.enroladores = [];
      this.loaderSelectEnrolador = true;
      if (idppvv !== 0) {
          this._NAOSService.getShopDetailById(idppvv).subscribe(
              response => {
                  if (response[0].jwhere.shop_type_id !== 2) {
                      this.puntoventa1 = idppvv;
                      this.puntoventa2 = '';
                  } else if (response[0].jwhere.shop_type_id == 2) {
                      this.puntoventa1 = '';
                      this.puntoventa2 = idppvv;
                  }
                  this._NAOSService.getEnroladres(response[0].where_id).subscribe(
                      responseEnroladores => {
                          for (let item of responseEnroladores) {
                              if (item.employeeTypeSd.en !== 'Delegate' && item.employeeTypeSd.en !== 'Trainer') {
                                  this.enroladores.push(item);
                              }
                          }
                          /* if (this.enroladores.length > 0) {
                              this.showInputEnrolador = true;
                          } else {
                              this.showInputEnrolador = false;
                          } */
                          this.showInputEnrolador = true;
                          this.loaderSelectEnrolador = false;
                      },
                      () => {
                          this.loaderSelectEnrolador = false;
                          console.error('Error al obtener los puntos de venta.');
                      }
                  );
              },
              error => {
                  console.error('Error ' + JSON.stringify(error));
              }
          );
      } else {
          this.loaderSelectEnrolador = false;
      }
      
  }

    /**
     * Funcion para chequear el codigo postal
     */
    public checkCP(): boolean {
        var valoresAceptados = /^[0-9]+$/;
        if (this.cp !== undefined && this.cp !== '') {
            if (this.cp.length == 5 && parseInt(this.cp) >= 1000 && parseInt(this.cp) <= 52999) {
                if (this.cp.match(valoresAceptados)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }

    }

    public validateName(): boolean {
        if (/^[a-zA-ZÁÉÍÓÚáéíóúñÑÀÈÌÒÙÜÇàèìòùüçºª'\.\-\s]{3,30}$/.test(this.name)) {
            return true;
        } else {
            return false;
        }
    }

    public validateSurname(): boolean {
        if (this.surname !== '') {
            if (/^[a-zA-ZÁÉÍÓÚáéíóúñÑÀÈÌÒÙÜÇàèìòùüç\-\s]{3,30}$/.test(this.surname)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    /**
     * Función para comprobar que no esta en los excluidos (Las islas canarias, Ceuta y Melilla)
     */
    public checkCPSpain(): boolean {
        let codigosExcluidos = ['51', '52', 'AD'];
        let auxError: number = 0;
        for (let item of codigosExcluidos) {
            let aux = this.cp.substr(0, 2);
            if (aux == item) {
                auxError++;
            }
        }
        if (auxError > 0) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Funcion para formatear el valor de legal
     */
    public formatLegal(data: boolean): string {
        if (data) {
            return 'S';
        } else {
            return 'N';
        }
    }

    /**
     * Validar contraseña entre 8-16 caracteres con mayusculas, minusculas y digitos.
     * @param password contraseña
     */
    public checkValidationPassword(password: string): boolean {
        let regexPassword = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,20}$/g;
        if (regexPassword.test(password)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Funcion para validar campos obligatorios
     */
    public checkFormulario(): boolean {
        if (this.name == undefined && this.email == undefined && this.password == undefined && this.repeatpassword == undefined && this.cp == undefined) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Funcion para validar email
     */
    public validateEmail(): boolean {
        let valido: boolean = true;
        if (environment.VALIDATIONEMAIL) {
            if (/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/.test(this.email)) {
                valido = true;
            } else {
                valido = false;
            }
        }
        return valido;
    }

}
