<!-- <div class="container containerMenuNaos" *ngIf="this.loader">
    <div class="row">
        <div class="col-md-10 botonMenu">
            <div class="row" style="margin-bottom: 5%">
                <div class="col-md-2 col-sm-2">
                    <div class="row">
                        <div class="col-md-12 recuadrogris">
                            <img class="iconGrey" [src]="this.iconPath" />
                            <span *ngIf="this.showOffer && this.index == 3" class="badge badge-notify">1</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 recuadroblanco">

                        </div>
                    </div>
                </div>
                <div class="col-md-10 col-sm-10">
                    <div class="row cardnaos">
                        <div  class="col-md-12 conenedorImagen" (click)="this.goToPage(this.path)">
                            <img class="imgcard" [src]="this.urlImage">
                            <div class="centrado">
                                {{this.nameOpt}}
                            </div>
                        </div> -->
                        <!-- <div *ngIf="this.nameOpt == 'Mis Compras'" class="col-md-12 conenedorImagen" (click)="this.bloquedZoneClic()">
                                <img class="imgcard" [src]="this.urlImage">
                                <div class="centrado">
                                    {{this.nameOpt}}
                                </div>
                            </div> -->
              <!--       </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->

<div class="container mb-5">
    <div class="row">
        <div class="col-2 p-0">
            <div class="header-card-menu p-2 text-center">
                <img [src]="this.iconPath" />
            </div>
        </div>
        <div class="col-md-10 p-0 body-card-menu  cursor-pointer" (click)="this.goToPage(this.path)">
            <img  [src]="this.urlImage">
            <label class="text-card-menu cursor-pointer py-2 px-4 font-weight-bold" [innerHTML]="this.nameOpt"></label>
        </div>
    </div>
</div>


<div class="modal fade" id="bloquedZone" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 filaLogo">
                        <img src="../../../assets/images/LOGO-NaosClub.png" />
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-12 filaLogo2" id="contenidodiv">
                        Muy pronto podrás subir tus tickets de compra, ¡te mantendremos informad@!
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btnPopUpFormsCerrar" data-dismiss="modal">Aceptar</button>
            </div>
        </div>
    </div>
</div>
