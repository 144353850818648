import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';

@Injectable()
export class CanActivateLoginRegistarGuard implements CanActivate{

    constructor(private _router: Router){}

    canActivate(){
        if(sessionStorage.getItem('ILCK')){
            return true;
        }else{
            this._router.navigate(['/login']);
            return false;
        }
    }

}