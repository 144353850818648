import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { NAOSService } from 'src/app/services/naos.service';
import { ExchangesService } from 'src/app/services/exchanges.service';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { DirectionService } from 'src/app/services/direction.service';
import { BagService } from 'src/app/services/bag.service';
import { LogService } from '@dagonmetric/ng-log';
declare var $: any;


@Component({
    selector: 'app-bag',
    templateUrl: './bag.component.html',
    styleUrls: ['./bag.component.css'],
    providers: [UserService, NAOSService, ExchangesService, StoreService, DirectionService, BagService, LogService]
})
export class BagComponent implements OnInit {

    public bag: Array<any>;
    public idclient: any;
    public scoreClient: string;
    public dataClient: any;
    public puntosVenta: Array<any>;
    public idppvvfarmacia: any;
    public idppvvfarmaciaSelected: boolean;
    public idppvvesteticaSelected: boolean;
    public idppvvEstetica: any;
    public dataDirections: Array<any>;

    public scoreTotal: string;
    public typeSendDomicilio: string;
    public typeSendCenter: string;

    public arrayProductsFormated: Array<any>;

    public emailCenter: boolean;
    public emailValue: string;
    public numberMobile: string;
    public direction: boolean;
    public directionValue: string;

    public addressMain: number;

    public contLoader: boolean;
    public contLoaderNum: number;

    public brandProduct: any;
    public idbrand: number;

    public useLocation: boolean;

    public latitud: any;
    public longitud: any;

    public showSendDirection: boolean;
    public showReciby: boolean;
    public sitesNear: Array<any>;
    public showFarmacia: boolean;
    public showCentroEstetica: boolean;
    public idpharmacyAllowed: boolean;
    public idsteticAllowed: boolean;

    public directionMainString: string;
    public centroRecogida: any;
    public geolocationAllowed: boolean;
    public contadorPedidos: number;
    public loaderConfirm: boolean;

    public objectResumen: any;
    public productFree: boolean;
    public typeSend: any;

    public textModal: string;
    public textUrl: string;

    public pageSite: number;
    public formatCodeShopToSendFarmacia: string;
    public formatCodeShopToSendEstetica: string;

    public scoreBag: any;
    public scoreClientCheckStar: any;
    public auxBeforeResult: any;

    public channelIdSite: any;

    public showOptSendDelivery: boolean;

    public textHidden:boolean;
    public disableButtonExchange: boolean;

    constructor(
        private _userService: UserService,
        private _naosService: NAOSService,
        private _exchangeService: ExchangesService,
        private _directionService: DirectionService,
        private _storeService: StoreService,
        private _router: Router,
        private _bagService: BagService,
        private readonly logService: LogService
    ) {
        this.bag = [];
        this.disableButtonExchange = false;
        this.arrayProductsFormated = [];
        this.emailCenter = false;
        this.dataDirections = [];
        this.contLoader = false;
        this.contLoaderNum = 0;
        this.brandProduct = undefined;
        this.showSendDirection = false;
        this.showReciby = true;
        this.sitesNear = [];
        this.puntosVenta = [];
        this.showFarmacia = false;
        this.showCentroEstetica = false;
        this.idppvvfarmaciaSelected = false;
        this.idppvvesteticaSelected = false;
        this.geolocationAllowed = false;
        this.productFree = false;
        this.idpharmacyAllowed = false;
        this.idsteticAllowed = false;
        this.contadorPedidos = 0;
        this.loaderConfirm = false;
        this.numberMobile = undefined;
        this.pageSite = 0;
        this.showOptSendDelivery = false;
        this.textHidden = true;
    }

    ngOnInit(): void {
        this.logService.trackPageView({
            name: 'Cesta compra',
            uri: '/home/store/bag'
        });
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        if (localStorage.getItem('BC' + this.idclient)) {
            this.bag = JSON.parse(localStorage.getItem('BC' + this.idclient));
            if (this.bag[0].json_type_ld === 'JREWARDS_PO') {
                this.showOptSendDelivery = false;
            } else {
                this.showOptSendDelivery = true;
            }
        }
        this.getScoreClient();
        this.getDataDirection();

        this.checkProductFree();
        this.getMarcasProductsIds();
        let intervaloBag = setInterval(() => {
            if (this.contLoaderNum >= 6 && this.bag.length > 0) {
                this.contLoader = true;
                clearInterval(intervaloBag);
            }
        }, 1000);
        this.setQuantityInit();
        this.getSites();
        this.getDataClient();
    }

    /**
     * Metodo para convertir las estrellas a euros
     * @param stars Estrellas del producto
     * @returns los euros a los que equivalen las estrellas
     */
    public convertStarsToEuros(stars: any): any {
        var euros: any;
        euros = stars / parseInt(sessionStorage.getItem('TAR'));
        return parseInt(euros);
    }

    /**
     * Funcion para obtener los sitios de compra
     */
    public getSites(): void {
        if (localStorage.getItem('ppvvs')) {
            this.puntosVenta = JSON.parse(localStorage.getItem('ppvvs'));
            let auxSites: Array<any> = [];
            for (let item of this.puntosVenta) {
                if (item.jwhere.allowExchanges && item.jwhere.where_legal_ld !== undefined && item.jwhere.where_legal_ld.indexOf('GENERIC') > -1) {
                    auxSites.push(item);
                }
            }
            this.puntosVenta = auxSites;
            this.contLoaderNum++;
        } else {
            this._naosService.getShopSite(this.pageSite).subscribe(
                response => {
                    this.puntosVenta = response;
                    let auxSites: Array<any> = [];
                    for (let item of this.puntosVenta) {
                        if (item.jwhere.allowExchanges && item.jwhere.where_legal_ld !== undefined && item.jwhere.where_legal_ld.indexOf('GENERIC') > -1) {
                            auxSites.push(item);
                        }
                    }
                    this.puntosVenta = auxSites;
                    this.contLoaderNum++;
                },
                () => {
                    this.contLoaderNum++;
                    console.error('Error al obtener los puntos de venta.');
                }
            );
        }
    }

    public formatCodeShopToSendFarmaciaById(idcenter: any): void {
        let obj: any;
        this._naosService.getShopDetailById(idcenter).subscribe(
            response => {
                this.formatCodeShopToSendFarmacia = response[0].where_cd;
                if (this.idppvvesteticaSelected) {
                    obj = {
                        'rewards': this.arrayProductsFormated,
                        'where_cd': this.formatCodeShopToSendFarmacia,
                        'ticket_cd': '',
                        'exchange_date': this._naosService.getDate()
                    };
                } else if (this.idppvvfarmaciaSelected) {
                    obj = {
                        'rewards': this.arrayProductsFormated,
                        'where_cd': this.formatCodeShopToSendEstetica,
                        'ticket_cd': '',
                        'exchange_date': this._naosService.getDate()
                    };
                }
                this._exchangeService.sendExchange(this.idclient, obj).subscribe(
                    () => {
                        this.bag = [];
                        $('#modalCanjeProductos').modal('hide');
                        this.textModal = 'Tu pedido ha sido realizado con éxito.';
                        this.textUrl = '/home/store/exchange';
                        $('#exampleModalNotificationBag').modal('show');
                        localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                        this.getScoreClient();
                        this.calcScore();
                        this.clickTypeCenter();
                        this.typeSendDomicilio = undefined;
                        this.typeSendCenter = undefined;
                        this.loaderConfirm = false;
                        this.contadorPedidos = 0;
                        this.ngOnInit();
                    },
                    () => {
                        this.loaderConfirm = false;
                        this.contadorPedidos = 0;
                        $('#modalCanjeProductos').modal('hide');
                        this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                        this.textUrl = null;
                        $('#exampleModalNotificationBag').modal('show');
                    }
                );
                localStorage.removeItem('email');
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    public formatCodeShopToSendEsteticaById(idcenter: any): void {
        let obj: any;
        this._naosService.getShopDetailById(idcenter).subscribe(
            response => {
                this.formatCodeShopToSendEstetica = response[0].where_cd;
                if (this.idppvvesteticaSelected) {
                    obj = {
                        'rewards': this.arrayProductsFormated,
                        'where_cd': this.formatCodeShopToSendFarmacia,
                        'ticket_cd': '',
                        'exchange_date': this._naosService.getDate()
                    };
                } else if (this.idppvvfarmaciaSelected) {
                    obj = {
                        'rewards': this.arrayProductsFormated,
                        'where_cd': this.formatCodeShopToSendEstetica,
                        'ticket_cd': '',
                        'exchange_date': this._naosService.getDate()
                    };
                }
                this._exchangeService.sendExchange(this.idclient, obj).subscribe(
                    () => {
                        this.bag = [];
                        $('#modalCanjeProductos').modal('hide');
                        this.textModal = 'Tu pedido ha sido realizado con éxito.';
                        this.textUrl = '/home/store/exchange';
                        $('#exampleModalNotificationBag').modal('show');
                        localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                        this.getScoreClient();
                        this.calcScore();
                        this.clickTypeCenter();
                        this.typeSendDomicilio = undefined;
                        this.typeSendCenter = undefined;
                        this.loaderConfirm = false;
                        this.contadorPedidos = 0;
                        this.ngOnInit();
                    },
                    () => {
                        this.loaderConfirm = false;
                        this.contadorPedidos = 0;
                        $('#modalCanjeProductos').modal('hide');
                        this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                        this.textUrl = null;
                        $('#exampleModalNotificationBag').modal('show');
                    }
                );
                localStorage.removeItem('email');
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    /**
     * Funcion para cambiar el numero de telefono según la direccion seleccionada
     * @param item Direccion elegida
     */
    public changeTelephoneNumber(item: any): void {
        this.numberMobile = item.contactPhone;
        this.objectResumen = {
            'contacto': item.contactName + ' ' + item.contactSurname + ' ' + item.contactSurname2,
            'telefono': this.numberMobile,
            'calle': item.addressName + ', Nº: ' + item.number,
            'cp': item.postalCode,
            'localidad': this.checkLocalityProvinceResumen(item.localty, item.province),
            'portal': item.portal,
            'escaleras': item.stairs,
            'planta': item.floor,
            'puerta': item.door,
            'otrosdatos': item.additionalAddress
        };

    }

    /**
     * Funcion con promesa para recibir las coordenadas de mi posición
     */
    public getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {
        navigator.geolocation.watchPosition(resp => {
            resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
            },
            err => {
            reject(err);
            });
        });
    }

    /**
     * Funcion para mostrar el mapa con los sitios donde recoger el pedido
     * @param type typo de lugar donde recoger el pedido
     */
    public goToMap(type: any): void {
        if (this.emailValue !== undefined) {
            localStorage.setItem('email', this.emailValue);
            if (type == 3) {
                localStorage.setItem('emailCenter', this.emailValue);
                this._router.navigate(['/home/store/mapnaos', type, this.idbrand]);
            } else if (type == 2) {
                if (this.idppvvEstetica !== '') {
                    this._naosService.getShopDetailById(this.idppvvEstetica).subscribe(
                        response => {
                            if (response[0].jwhere.allowExchanges) {
                                this.channelIdSite = response[0].jwhere.channel_id;
                                this.centroRecogida = response[0];
                                this.idsteticAllowed = true;
                            }
                        },
                        error => {
                            console.error('Error ' + JSON.stringify(error));
                        }
                    );
                }
                this.typeSendCenter = '1';
                this.typeSend = '2';
                this.showFarmacia = false;
                this.showCentroEstetica = true;

                this.idppvvfarmaciaSelected = true;
                this.idppvvesteticaSelected = false;

                $('#buttonFarmacia').removeClass('btnSitioSelected');
                $('#buttonFarmacia').addClass('btnSitio');

                $('#buttonEstetica').removeClass('btnSitio');
                $('#buttonEstetica').addClass('btnSitioSelected');
            } else if (type == 1) {
                if (this.idppvvfarmacia !== '') {
                    this._naosService.getShopDetailById(this.idppvvfarmacia).subscribe(
                        response => {
                            if (response[0].jwhere.allowExchanges) {
                                this.channelIdSite = response[0].jwhere.channel_id;
                                this.centroRecogida = response[0];
                                this.idpharmacyAllowed = true;
                            }
                        },
                        error => {
                            console.error('Error ' + JSON.stringify(error));
                        }
                    );
                }
                this.typeSendCenter = '1';
                this.typeSend = '1';
                this.showFarmacia = true;
                this.showCentroEstetica = false;

                this.idppvvfarmaciaSelected = false;
                this.idppvvesteticaSelected = true;

                $('#buttonEstetica').removeClass('btnSitioSelected');
                $('#buttonEstetica').addClass('btnSitio');

                $('#buttonFarmacia').removeClass('btnSitio');
                $('#buttonFarmacia').addClass('btnSitioSelected');
            }
        } else {
            this.textModal = 'Para realizar el canje es imprescindible que indiques un email al que te enviaremos el código que deberás presentar en el punto de venta para recoger tu pedido.';
            this.textUrl = null;
            $('#exampleModalNotificationBag').modal('show');

        }
    }

    public removeWhiteSpace(texto: string): string {
        return texto.replace(' ', '+');
    }

    /**
     * Función para comprobar si existe producto gratis
     */
    public checkProductFree(): void {
        if (localStorage.getItem('offerFree') === 'true') {
            this.productFree = true;

            for (let item of this.bag) {
                if (item.jwhat.item_sd === 'Sensibio H2O' && this.productFree) {
                    item.jwhat.attribute_desc = {
                        'es': item.attributes[0].value.es
                    };
                    item.destiny = 'catalogo';
                }
            }
        } else {
            this.productFree = false;
        }
    }

    /**
     * Funcion para modificr el telefono del resumen si ha sido modificado
     */
    public setTelephoneResume(): void {
        this.objectResumen.telefono = this.numberMobile;
    }

    /**
     * Fucnion para obtener las direcciones del cliente
     */
    public getDataDirection(): void {
        this._userService.getDataDirectionClient(this.idclient).subscribe(
            response => {
                this.dataDirections = response;
                let auxDirections: Array<any> = [];
                for (let item of this.dataDirections) {
                    if (item.addressName.indexOf('GENERIC') > -1) {

                    } else {
                        if (item.deliveryAvailable) {
                            auxDirections.push(item);
                        }
                    }
                }
                this.dataDirections = auxDirections;
                if (this.dataDirections !== null) {
                    for (let item of this.dataDirections) {
                        if (item.mainAddress) {
                            this.addressMain = item.idAddress;
                            this.numberMobile = item.contactPhone;
                            this.directionMainString = item.number + '+' + this.removeWhiteSpace(item.addressName) + ',+' + this.removeWhiteSpace(item.localty) + ',+' + this.removeWhiteSpace(item.province);
                            this.objectResumen = {
                                'contacto': item.contactName + ' ' + item.contactSurname,
                                'telefono': this.numberMobile,
                                'calle': item.addressName + ' ' + item.number,
                                'cp': item.postalCode,
                                'localidad': this.checkLocalityProvinceResumen(item.localty, item.province),
                                'portal': item.portal,
                                'escaleras': item.stairs,
                                'planta': item.floor,
                                'puerta': item.door
                            };
                            this.latitud = item.latitude;
                            this.longitud = item.longitude;
                            this.getPointsNearClient();
                        }
                    }
                } else {
                    this.dataDirections = [];
                }
                this.contLoaderNum++;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
                this.contLoaderNum++;
            }
        );
    }

    /**
     * Funcion para obtener los ids de las marcas bioderma y esthederm
     */
    public getMarcasProductsIds(): void {
        this._storeService.getMarcasIds().subscribe(
            response => {
                if (this.bag.length > 0) {
                    if (this.bag[0].classification[0] == response[0].id) {
                        this.idbrand = 1; // Bioderma
                        this.brandProduct = 'BIODERMA';
                    } else if (this.bag[0].classification[0] == response[1].id) {
                        this.idbrand = 2; // Esthederm
                        this.brandProduct = 'ESTHEDERM';
                    }
                }
                this.contLoaderNum++;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
                this.contLoaderNum++;
            }
        );
    }

    /**
     * Funcion para borrar un producto de la cesta
     * @param idproduct que queremos borrar
     */
    public deleteProductBag(idproduct: any): void {
        for (let x = 0; x < this.bag.length; x++) {
            if (this.bag[x].what_id == idproduct) {
                if (this.bag[x].jwhat.delivery_type === '2') {
                    localStorage.setItem('offerFree', 'false');
                }
                this.bag.splice(x, 1);
            }
        }
        this.calcScore();
        localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
        if (this.bag.length == 0) {
            this._router.navigate(['/home/store/catalogo']);
        }
    }

    /**
     * Funcion para obtener las estrellas del cliente
     */
    public getScoreClient(): void {
        this._userService.getScoreClient(this.idclient).subscribe(
            response => {
                this.scoreClient = response[0].available;
                this.scoreClientCheckStar = response[0].available;
                this.calcScore();
                this.contLoaderNum++;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
                this.contLoaderNum++;
            }
        );
    }

    /**
     * Funcion para obtener los datos del cliente
     */
    public getDataClient(): void {
        this._userService.getDataClient(this.idclient).subscribe(
            response => {
                this.dataClient = response;
                this.idppvvfarmacia = this.dataClient.whereid_1;
                if (this.idppvvfarmacia !== '') {
                    this._naosService.getShopDetailById(this.idppvvfarmacia).subscribe(
                        response => {
                            if (response[0].jwhere.allowExchanges) {
                                if (this.idbrand === response[0].jwhere.shop_brand_id || response[0].jwhere.shop_brand_id === 3) {
                                    this.centroRecogida = response[0];
                                    this.idpharmacyAllowed = true;
                                }

                            }
                        },
                        error => {
                            console.error('Error ' + JSON.stringify(error));
                        }
                    );
                }
                this.idppvvEstetica = this.dataClient.whereid_2;
                if (this.idppvvEstetica !== '') {
                    this._naosService.getShopDetailById(this.idppvvEstetica).subscribe(
                        response => {
                            if (response[0].jwhere.allowExchanges) {
                                if (this.idbrand === response[0].jwhere.shop_brand_id || response[0].jwhere.shop_brand_id === 3) {
                                    this.centroRecogida = response[0];
                                    this.idsteticAllowed = true;
                                }
                            }
                        },
                        error => {
                            console.error('Error ' + JSON.stringify(error));
                        }
                    );
                }
                this.emailValue = response.email;
                this.contLoaderNum++;
            },
            error => {
                console.error('Error ' + error.error.message);
                this.contLoaderNum++;
            }
        );
    }

    /**
     * Meter la cantidad en los objetos
     */
    public setQuantityInit(): void {
        for (let item of this.bag) {
            item.quantity = 1;
        }
        localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
        this.contLoaderNum++;
    }

    /**
     * Funcion para obtener las estrellas que llevamos en la bolsa
     */
    public getScoreBagClient(): number {
        let auxCont: number = 0;
        for (let item of this.bag) {
            auxCont = auxCont + this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units);
        }
        return auxCont;
    }

    /**
     * Funcion para controlar que los productos que metemos en la bolsa de compra no supera el numero de estrellas del cliente
     */
    public checkScoreProductsClient(): boolean {
        let permiso: boolean = false;
        this.scoreBag = this.getScoreBagClient();
        if (parseInt(this.scoreTotal) <= parseInt(this.scoreClientCheckStar)) {
            permiso = true;
        } else {
            permiso = false;
        }
        return permiso;
    }

    /**
     * Funcion para actualizar la cantidad de productos en el carrito
     */
    public setQuantityBagClient(index: any, quantity: any): void {
        if (this.checkScoreProductsClient()) {
            this.bag[index].quantity = quantity;
            localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
        } else {
            this.bag[index].quantity = quantity - 1;
            this.textModal = 'Lo sentimos, saldo insuficiente.';
            this.textUrl = null;
            this.calcScore();
            $('#exampleModalNotificationBag').modal('show');
            $('#exampleModalNotificationBag').modal({backdrop: 'static', keyboard: false});
        }
    }

    /**
     * Funcion para calcular el total de estrellas en la cesta
     */
    public calcScore(): void {
        let resultado: number = 0;
        if (this.bag.length > 0) {
            for (let item of this.bag) {
                if (this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) == 0) {
                    resultado = resultado + 0;
                } else {
                     resultado = resultado + (this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) * item.quantity);
                }
            }
            this.scoreTotal = resultado.toString();
            this.contLoaderNum++;
        } else {
            this.scoreTotal = '0';
            this.contLoaderNum++;
        }
    }

    /**
     * Funcion para formatear los productos para enviar a centro
     * @param arrayProducts array de productos de la cesta
     */
    public formatProductsCenterArray(arrayProducts: Array<any>): void {
        if (arrayProducts.length > 0) {
            for (let item of arrayProducts) {
                let obj = {
                    'reward_quantity': item.quantity,
                    'what_cd': item.what_cd,
                    'json_type_ld': 'JREWARDS_PF',
                    'management': {
                        'email': this.emailValue
                    }
                };
                this.arrayProductsFormated.push(obj);
            }
        }
        this.disableButtonExchange = false;
    }

    /**
     * Funcion para limpiar array de productos si cancelamos el canje desde el popup
     */
    public cleanExchanges(): void {
      this.arrayProductsFormated = [];
    }

    /**
     * Funcion para formatear los productos digitales
     * @param arrayProducts array de productos de la cesta
     */
    public formatProductsDigital(arrayProducts: Array<any>): void {
        if (arrayProducts.length > 0) {
            for (let item of arrayProducts) {
                let obj = {
                    'reward_quantity': item.quantity,
                    'what_cd': item.what_cd,
                    'json_type_ld': 'JREWARDS_PO',
                    'management': {
                        'email': this.emailValue
                    }
                };
                this.arrayProductsFormated.push(obj);
            }
        }
        this.disableButtonExchange = false;
    }

    /**
     * Funcion para formatear los productos para enviar a domicilio
     * @param arrayProducts array de productos de la cesta
     */
    public formatProductsDomicilioArray(arrayProducts: Array<any>): void {
        if (arrayProducts.length > 0) {
            for (let item of arrayProducts) {
                let obj = {
                    'reward_quantity': item.quantity,
                    'what_cd': item.what_cd,
                    'json_type_ld': 'JREWARDS_PF',
                    'management': {
                        'customer_address_id': this.addressMain,
                        'logistic_operator': 'seur'
                    }
                };
                this.arrayProductsFormated.push(obj);
            }
        }
        this.disableButtonExchange = false;
    }

    /**
     * Funcion para mostrar campo email si se elige la opcion de recogida en centro
     */
    public clickTypeCenter(): void {
        this.direction = false;
        $('#domicilio').prop('checked', false);
        if (this.emailCenter) {
            this.emailCenter = false;
        } else {
            this.emailCenter = true;
            this.typeSendDomicilio = undefined;
        }
    }

    /**
     * Funcion para calcular el saldo despues de la compra
     */
    public calcScoreBuy(): string {
        let saldoAfter: number;
        saldoAfter = parseInt(this.scoreClient) - parseInt(this.scoreTotal);
        return saldoAfter.toString();
    }

    /**
     * Funcion para mostrar solo una vez el campo si la localidad y la provincia vienen iguales
     */
    public checkLocalityProvince(locality: string, province: string): string {
        if (locality === province) {
            return locality;
        } else {
            return locality;
        }
    }

    /**
     * Funcion para mostrar solo una vez el campo si la localidad y la provincia vienen iguales
     */
    public checkLocalityProvinceResumen(locality: string, province: string): string {
        if (locality === province) {
            return locality;
        } else {
            return locality + ', ' + province;
        }
    }

    /**
     * Funcion para mostrar las direcciones si se muestra envio a domicilio
     */
    public clickTypeDirection(): void {
        this.emailCenter = false;
        $('#centro').prop('checked', false);
        if (this.direction) {
            this.direction = false;
        } else {
            this.direction = true;
            this.typeSendCenter = undefined;
        }
    }

    /**
     * Envio de pedidos
     */
    public sendExchangeProduct(): void {
        if (this.bag.length > 0) {
          this.disableButtonExchange = true;
            if (this.bag[0].json_type_ld !== 'JREWARDS_PO') {
                if (this.typeSendDomicilio == undefined && this.typeSendCenter == undefined) {
                    this.textModal = 'Para realizar el canje es imprescindible que indiques una opción de envío.';
                    this.textUrl = null;
                    $('#exampleModalNotificationBag').modal('show');
                    $('#exampleModalNotificationBag').modal({backdrop: 'static', keyboard: false});
                    this.disableButtonExchange = false;
                } else {
                    if (this.typeSendDomicilio == '1') {
                        if (this.numberMobile !== undefined && this.numberMobile !== '') {
                            if (this.addressMain !== undefined) {
                                for (let item of this.dataDirections) {
                                    if (item.idAddress == this.addressMain) {
                                        let obj = {
                                            'postalCode': item.postalCode,
                                            'number': item.number.toString(),
                                            'contactName': item.contactName,
                                            'addressType': item.addressType,
                                            'localty': item.localty,
                                            'alias': item.alias,
                                            'municipalityIne': item.municipalityIne,
                                            'mainAddress': item.mainAddress,
                                            'contactSurname2': item.contactSurname2,
                                            'stairs': item.stairs,
                                            'contactSurname': item.contactSurname,
                                            'door': item.door,
                                            'portal': item.portal,
                                            'floor': item.floor,
                                            'country': item.country,
                                            'additionalAddress': item.additionalAddress,
                                            'addressName': item.addressName,
                                            'province': item.province,
                                            'contactPhone': this.numberMobile.replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '')
                                        };
                                        item.contactPhone = this.numberMobile;
                                        this._directionService.updateDirectionClient(this.idclient, item.idAddress, obj).subscribe(
                                         () => {},
                                            error => {
                                                console.error('Error' + JSON.stringify(error));
                                            }
                                        );
                                    }
                                }
                                this.formatProductsDomicilioArray(this.bag);
                                $('#modalCanjeProductos').modal('show');
                                $('#modalCanjeProductos').modal({backdrop: 'static', keyboard: false});
                            } else {
                                this.contLoader = true;
                                this.textModal = 'Para realizar el canje es imprescindible que indiques una dirección para hacer el envío.';
                                this.textUrl = null;
                                $('#exampleModalNotificationBag').modal('show');
                                this.disableButtonExchange = false;
                            }
                        } else {
                            this.contLoader = true;
                            this.textModal = 'Para realizar el canje es imprescindible que indiques un teléfono al que te podamos llamar, en caso de incidencia en el envío.';
                            this.textUrl = null;
                            $('#exampleModalNotificationBag').modal('show');
                            this.disableButtonExchange = false;
                        }
                    } else if (this.typeSendCenter == '1') {
                        if (this.emailValue !== undefined && this.emailValue !== '') {
                            if (this.typeSend == '1' || this.typeSend == '2') {
                                this.formatProductsCenterArray(this.bag);
                                $('#modalCanjeProductos').modal('show');
                                $('#modalCanjeProductos').modal({backdrop: 'static', keyboard: false});
                            } else {
                                this.contLoader = true;
                                this.textModal = 'Para realizar el canje es imprescindible que indiques donde recoger el pedido.';
                                this.textUrl = null;
                                $('#exampleModalNotificationBag').modal('show');
                                this.disableButtonExchange = false;
                            }
                        } else {
                            this.contLoader = true;
                            this.textModal = 'Para realizar el canje es imprescindible que indiques un email al que te enviaremos el código que deberás presentar en el punto de venta para recoger tu pedido.';
                            this.textUrl = null;
                            $('#exampleModalNotificationBag').modal('show');
                            this.disableButtonExchange = false;
                        }
                    }
                }
            } else {
                this.formatProductsDigital(this.bag);
                $('#modalCanjeProductos').modal('show');
                $('#modalCanjeProductos').modal({backdrop: 'static', keyboard: false});
            }

        } else {}
    }

    /**
     * Funcion para enviar un canje de envío
     */
    public exchangeProductsDomicilio(): void {
        if (this.contadorPedidos === 0 && this.arrayProductsFormated.length > 0) {
            this.contadorPedidos++;
            this.loaderConfirm = true;
            let obj = {
                'rewards': this.arrayProductsFormated,
                'where_cd': '00001',
                'ticket_cd': '',
                'exchange_date': this._naosService.getDate()
            };
            this._exchangeService.sendExchange(this.idclient, obj).subscribe(
                () => {
                    $('#modalCanjeProductos').modal('hide');
                    this.textModal = 'Tu pedido ha sido realizado con éxito.';
                    this.textUrl = '/home/store/exchange';
                    $('#exampleModalNotificationBag').modal('show');
                    this.bag = [];
                    localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                    this.getScoreClient();
                    this.calcScore();
                    this.clickTypeDirection();
                    this.typeSendDomicilio = undefined;
                    this.typeSendCenter = undefined;
                    this.loaderConfirm = false;
                    this.contadorPedidos = 0;
                },
                error => {
                    this.loaderConfirm = false;
                    this.contadorPedidos = 0;
                    $('#modalCanjeProductos').modal('hide');
                    this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                    this.textUrl = null;
                    $('#exampleModalNotificationBag').modal('show');
                }
            );
            localStorage.removeItem('email');
        }
    }

    /**
     * Funcion para enviar un canje de recogida
     */
    public exchangeProductsCenter(): void {
        if (this.contadorPedidos === 0 && this.arrayProductsFormated.length > 0) {
            this.contadorPedidos++;
            this.loaderConfirm = true;
            if (this.idppvvesteticaSelected) {
                this.formatCodeShopToSendFarmaciaById(this.idppvvfarmacia);

            } else if (this.idppvvfarmaciaSelected) {
                this.formatCodeShopToSendEsteticaById(this.idppvvEstetica);
            }
        }
    }

    public exchangeProductsDigital(): void {
        if (this.contadorPedidos === 0 && this.arrayProductsFormated.length > 0) {
            this.contadorPedidos++;
            this.loaderConfirm = true;
            let obj = {
                'rewards': this.arrayProductsFormated,
                'where_cd': 'PPVV_CANJE_DIGITAL',
                'ticket_cd': '',
                'exchange_date': this._naosService.getDate()
            };
            this._exchangeService.sendExchange(this.idclient, obj).subscribe(
            () => {
                this.bag = [];
                $('#modalCanjeProductos').modal('hide');
                this.textModal = 'Tu pedido ha sido realizado con éxito.';
                this.textUrl = '/home/store/exchange';
                $('#exampleModalNotificationBag').modal('show');
                localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                this.getScoreClient();
                this.calcScore();
                this.clickTypeCenter();
                this.typeSendDomicilio = undefined;
                this.typeSendCenter = undefined;
                this.loaderConfirm = false;
                this.contadorPedidos = 0;
            },
            () => {
                this.loaderConfirm = false;
                this.contadorPedidos = 0;
                $('#modalCanjeProductos').modal('hide');
                this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                this.textUrl = null;
                $('#exampleModalNotificationBag').modal('show');
            }
        );
        }
    }


    /**
     * Funcion para obtener los ppvv cercanos al cliente
     */
    public getPointsNearClient(): void {
        this._userService.getPointsNearClient(this.brandProduct, this.latitud, this.longitud).subscribe(
            response => {
                this.sitesNear = response;
                let auxDirectionsNear: Array<any> = [];
                for (let item of this.sitesNear) {
                    if (item.jwhere.allowExchanges) {
                        auxDirectionsNear.push(item);
                    }
                }

                this.sitesNear = auxDirectionsNear;
                if (this.sitesNear.length > 0) {
                    this.showSendDirection = false;
                    this.showReciby = true;
                } else {
                    this.showSendDirection = true;
                    this.showReciby = true;
                }
            },
            error => {
                console.error('Error al encontrar los sitios cercanos.');
            }
        );
    }

    /**
     * Metodo para coger solamente las unidades de fidelización con id 7
     * @param arrayUnit array de unidades de fidelización del producto
     */
    public showLoyaltyUnitPrize(arrayUnit: Array<any>): number {
      let prize: number = 0;
      let contNotFound: number = 0;
      for (let item of arrayUnit) {
        if (item.loyalty_unit_id == 7) {
          contNotFound++;
          prize = item.loyalty_unit_quantity;
        }
      }
      if (contNotFound > 0) {
        return prize;
      } else {
        return 0;
      }
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            /* this._router.navigate([this.textUrl], {skipLocationChange: true}) */
            this._router.navigateByUrl('/refresh', {skipLocationChange: true}).then(()=> this._router.navigate([this.textUrl]));
        }
    }

    public checkDisabledButtonExchange(): boolean {
        if (this.bag.length == 0) {

        } else {
            return true;
        }
    }
}
