import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TestService } from '../../services/test.service';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { MediktorService } from 'src/app/services/mediktor.service';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { LogService } from '@dagonmetric/ng-log';
import { NAOSService } from 'src/app/services/naos.service';
declare var jQuery: any;
declare var $: any;


@Component({
    selector: 'app-naos-skintest',
    templateUrl: './skintest.component.html',
    styleUrls: ['./skintest.component.css'],
    providers: [TestService, MediktorService, UserService, LogService, NAOSService]
})
export class SkinTestComponent implements OnInit {


    public idclient: any;
    public infoClient: any;
    public dataReport: any;
    public uuidMediktorUSer: string;
    public externUserId: any;
    public deviceId: any;
    public authToken: any;
    public urlIframe: any;
    public testList: Array<any>;
    public showButtonProfile: boolean;
    public showIframe: boolean;
    public productsRecomended: Array<any>;
    public lastReportTest: any;

    public textModal: string;
    public textUrl: string;

    constructor(
        private _testService: TestService,
        private _router: Router,
        private _mediktorService: MediktorService,
        private _userService: UserService,
        private sanitizer: DomSanitizer,
        private readonly logService: LogService,
        private _naosService: NAOSService
    ) {
        this.showIframe = false;
        this.externUserId = undefined;
        this.deviceId = undefined;
        this.authToken = undefined;
        this.productsRecomended = [];
        this.testList = [];
        this.showButtonProfile = false;
    }

    ngOnInit(): void {
        this.logService.trackPageView({
            name: 'Test piel',
            uri: '/home/skintest'
        });
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        if (localStorage.getItem('uuid')) {
            this.uuidMediktorUSer = localStorage.getItem('uuid');
        } else {
            this.uuidMediktorUSer = uuidv4();
        }
        this.getDataClient();

        this.getDataReportLocalStorage('reportInfo');
        this.addEventResult();
    }


    public goToHome(): void {
        this._router.navigate(['/home']);
    }


    /**
     * Funcion para obtener los datos del cliente
     */
    public getDataClient(): void {
        this._userService.getDataClient(this.idclient).subscribe(
            response => {
                this.infoClient = response;
                this.doLoginMediktor();
            },
            error => {
                console.error('Error ' + error.error.message);
            }
        );
    }

    /**
     * Funcion para formatear el sexo del cliente
     * @param sex Sexo del cliente
     */
    public formatSex(sex: string): number {
        if (sex !== null && sex !== undefined) {
            switch (sex) {
                case 'H':
                    return 0;
                case 'M':
                    return 1;
                case 'O':
                    return 2;
            }
        } else {
            return 0;
        }

    }

    public formatSexUpdate(sex: string): string {
        if (sex !== null && sex !== undefined) {
            switch (sex) {
                case 'MALE':
                    return 'H';
                case 'FEMALE':
                    return 'M';
                case 'OTHER':
                    return 'O';
            }
        } else {
            return '';
        }
    }

    /**
     * Funcion para hacer login con Mediktor
     */
    public doLoginMediktor(): void {
        let obj = {
            'deviceId': this.uuidMediktorUSer,
            'externUser': {
                'partnerExternalId': this.idclient,
                'birthDate': this.infoClient.datebirth,
                'gender': this.formatSex(this.infoClient.sex),
                'newPassword': 'Naos0109',
                'username': this.uuidMediktorUSer,
            },
            'includeAuthToken': true
        };
        this._mediktorService.authenticationMediktor(obj).subscribe(
            response => {
                this.externUserId = response.externUser.externUserId;
                this.deviceId = this.uuidMediktorUSer;
                this.authToken = response.authToken;

                if (this.externUserId !== undefined && this.authToken !== undefined && this.deviceId !== undefined) {
                    this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.MEDIKTORAPITEST + '?externUserId=' + this.externUserId + '&deviceId=' + this.deviceId + '&authToken=' + this.authToken);
                    this.showIframe = true;
                } else {
                    this.showIframe = false;
                }

            },
            error => {
                console.error('Error' + JSON.stringify(error));
                this.textModal = 'Ha ocurrido un error. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                this.textUrl = '/home/skintest';
                $('#exampleModalNotificationTestSkin').modal('show');
                this.showIframe = true;
            }
        );
    }


    /**
     * Funcion para guardar los datos del tipo de piel en los datos del cliente de LSP
     */
    public updateDataClient(): void {
        this._userService.updateDataClient(this.idclient, this.infoClient).subscribe(
            () => {},
            error => {
                console.error('Error' + JSON.stringify(error));
            }
        );
    }


    /**
     * Funcion para recoger la infor del resumen del reporte
     */
    public getDataReportLocalStorage(name: string): any {
        let intervalo = setInterval(() => {
            if (localStorage.getItem(name) && localStorage.getItem(name) !== '"HelloParentFrame!"') {
                this.dataReport = JSON.parse(localStorage.getItem(name));
                if (this.dataReport.type == undefined) {
                    if (this.dataReport.value.summarySessionConclusionList !== undefined) {
                        this.infoClient.skinType = this.dataReport.value.summarySessionConclusionList[0].propertyValueList[0].value;
                        for (let item of this.dataReport.value.summarySessionConclusionList[0].actionList) {
                            for (let itemProduct of item.propertyValueList) {
                                if (itemProduct.name === 'EANCode') {
                                    this.productsRecomended.push(itemProduct.value);
                                }
                            }
                        }
                        if (this.externUserId !== null && this.externUserId !== undefined && this.authToken !== null && this.authToken !== undefined && this.deviceId !== null && this.deviceId !== undefined) {
                            this.getTestInformsMediktor(this.externUserId, this.authToken, this.deviceId);
                            clearInterval(intervalo);
                        }
                        localStorage.removeItem('reportInfo');
                        this.showButtonProfile = true;
                    }
                }
            }
        }, 1000);
    }

    /**
     * Funcion para obtener los informes de test de Mediktor por cliente
     */
    public getTestInformsMediktor(exterUserId: any, authToken: any, deviceId: any): void {
        this._mediktorService.getListTest(this.idclient, 0, 10, exterUserId, authToken, deviceId).subscribe(
            response => {
                this.testList = response.sessionList;
                this.lastReportTest = this.testList[0];
                if (this.lastReportTest.externUserId == this.externUserId) {
                    this.infoClient.recommendedProduct = this.productsRecomended;
                    this.infoClient.mediktorExternUserId = this.externUserId;
                    this.infoClient.mediktorDeviceId = this.deviceId;
                    this.infoClient.mediktorAuthtoken = this.authToken;
                    if (this.infoClient.sex == '') {
                        this.infoClient.sex = this.formatSexUpdate(this.testList[0].sex);
                    }
                    if (this.infoClient.datebirth == '') {
                        this.infoClient.datebirth = this._naosService.setFormatTimestampDate2(this.lastReportTest.birthDate);
                    }
                    this.updateDataClient();
                }
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    /**
     * Funcion que pone a escuchar un evento para lo que venga por el iframe
     */
    public addEventResult(): void {
        window.addEventListener('message', function(e) {
             let message = e.data;
             this.localStorage.setItem('reportInfo', JSON.stringify(message));
         });
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }

}
