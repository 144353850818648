<!-- <div *ngFor="let optMenu of this.arrOptionsMenu">
    <naos-cardmenu [urlImage]="optMenu.urlimage" [nameOpt]="optMenu.name" [description]="optMenu.text_description" [path]="optMenu.path"></naos-cardmenu>
</div> -->

<!-- TODO: this component is not used.
<div class="container mw-90 w-mobile-100 mw-mobile-100">
    <div class="row justify-content-between">
        <div class="col-sm-12 col-md-5" *ngFor="let optMenu of this.arrOptionsMenu;let i = index">
            <app-naos-cardmenu [index]="i" [urlImage]="optMenu.urlimage" [nameOpt]="optMenu.name" [iconPath]="optMenu.iconGrey" [path]="optMenu.path"></app-naos-cardmenu>
        </div>
    </div>
</div> -->


<div class="container mw-70 w-mobile-90 mw-mobile-90">
    <div class="row text-center align-self-center justify-content-center text-center" *ngFor="let optMenu of this.arrOptionsMenu;let i = index">
        <div class="card card-menu mb-4 cursor-pointer" (click)="this.goToPage(optMenu.path)">
            <div class="row no-gutters">
                <div class="col-md-5">
                    <img class="card-img card-img-menu h-100" [src]="optMenu.urlimage">
                </div>
                <div class="col-md-7">
                    <div class="card-body cursor-pointer">
                        <label class="title-card px-4 font-weight-bold cursor-pointer" [innerHTML]="optMenu.name"></label>
                        <hr>
                        <label class="text-body-card cursor-pointer" [innerHTML]="optMenu.description"></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
