<div class="container w-100 mw-100">
    <div class="row loader" *ngIf="!this.loader || this.buttonCancel">
        <div class="col-md-12 text-center">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>

    <div *ngIf="this.loader || !this.buttonCancel">
        <div class="row justify-content-center mb-2 mb-md-4">
            <div class="col-12 col-sm-6 col-md-5 col-lg-3">
                <div class="form-group">
                    <input type="date" (change)="this.setFilterValuesIni($event.target.value)"
                        [(ngModel)]="this.dateIni" class="form-control inputRegisterDate" id="dateInit">
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-5 col-lg-3">
                <div class="form-group">
                    <input type="date" (change)="this.setFilterValuesFin($event.target.value)"
                        [(ngModel)]="this.dateFin" class="form-control inputRegisterDate" id="dateFin">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" *ngIf="this.exchangesList.length > 0">
                <div class="hidden-mobile">
                    <table class="table table-borderless px-5">
                        <thead>
                            <tr>
                                <th scope="col">Nº Pedido</th>
                                <th scope="col">
                                    Entrega
                                    <i [placement]="['right', 'bottom', 'auto']"
                                        ngbPopover="(i) Si seleccionaste recogida en punto de venta, recuerda que te hemos enviado por email el código que deberás presentar para recoger el pedido antes de 30 días.
                                                    (ii) Si seleccionaste envío a domicilio, recuerda que el plazo de envío será de 10 a 15 días." class="fas fa-info-circle iconInfo"></i>
                                </th>
                                <th scope="col">Fecha</th>
                                <th scope="col">
                                    Estado
                                    <ng-template #actionsInfo>
                                        <div class="mb-2">
                                            <i class="fa fa-reply-all iconAction info ml-0 mr-2" aria-hidden="true"></i>Reenvío de documentación
                                        </div>
                                        <div class="mb-2">
                                            <i class="fas fa-comment-dots iconAction info ml-0 mr-2"></i>Notificar incidencia o consulta
                                        </div>
                                        <div>
                                            <i class="fa fa-times-circle-o iconAction info ml-0 mr-2" aria-hidden="true"></i>Cancelar
                                        </div>
                                    </ng-template>
                                    <i [placement]="['right', 'bottom', 'auto']" [ngbPopover]="actionsInfo"
                                        class="fas fa-info-circle iconInfo hidden-desktop hidden-mobile"></i>
                                </th>
                                <th scope="col" class="text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let item of this.exchangesList;let i = index">
                                <tr data-toggle="collapse" [attr.href]="'#collapseExample'+i">
                                    <td>&nbsp;{{ item.exchange_id }}&nbsp;</td>
                                    <td>{{ this.formatSiteExchange(item.where_ld)}}</td>
                                    <td>{{ this.formatDateExchange(item.exchange_date) }}</td>
                                    <td>{{ item.status.message.es}}</td>
                                    <td class="lastColumn">
                                        <div class="row">
                                            <!-- Boton de reenviar datos -->
                                            <div class="col-md-4 col-sm-4"
                                                *ngIf="this.comprobarStatusReenviarOrder(i)">
                                                <i (click)="this.reSendDataExchange(item.lcpInteractionId)"
                                                    [placement]="['right', 'bottom', 'auto']"
                                                    ngbPopover="Reenvío de documentación"
                                                    class="fa fa-reply-all iconAction" aria-hidden="true"></i>
                                            </div>
                                            <div class="col-md-4 col-sm-4"
                                                *ngIf="!this.comprobarStatusReenviarOrder(i)">
                                                <i [placement]="['right', 'bottom', 'auto']"
                                                    ngbPopover="Reenvío de documentación"
                                                    class="fa fa-reply-all iconActionDisabled" aria-hidden="true"></i>
                                            </div>
                                            <!-- Boton de comentario -->
                                            <div class="col-md-4 col-sm-4"
                                                *ngIf="this.comprobarStatusComment(i)">
                                                <i [placement]="['right', 'bottom', 'auto']"
                                                    ngbPopover="Notificar incidencia o consulta"
                                                    (click)="this.goToContact(item.reward_info[0][0].interaction_id)"
                                                    class="fas fa-comment-dots iconAction"></i>
                                            </div>
                                            <div class="col-md-4 col-sm-4"
                                                *ngIf="!this.comprobarStatusComment(i)">
                                                <i [placement]="['right', 'bottom', 'auto']"
                                                    ngbPopover="Notificar incidencia o consulta"
                                                    class="fas fa-comment-dots iconActionDisabled"></i>
                                            </div>
                                            <!-- Boton de cancelar -->
                                            <div class="col-md-4 col-sm-4"
                                                *ngIf="this.comprobarStatusCancelOrder(i)">
                                                <i [placement]="['right', 'bottom', 'auto']" ngbPopover="Cancelar"
                                                    (click)="this.setExchangeCancel(item.exchange_id)"
                                                    data-toggle="modal" data-target="#modalCancelacion"
                                                    class="fa fa-times-circle-o iconAction" aria-hidden="true"></i>
                                            </div>
                                            <div class="col-md-4 col-sm-4"
                                                *ngIf="!this.comprobarStatusCancelOrder(i)">
                                                <i [placement]="['right', 'bottom', 'auto']" ngbPopover="Cancelar"
                                                    class="fa fa-times-circle-o iconActionDisabled"
                                                    aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="collapse collapseProducts"
                                    *ngFor="let item2 of this.formatArrayProductosPedido(item.reward_info);let x = index;"
                                    [id]="'collapseExample'+i">
                                    <td>{{ item2.item_ld }}</td>
                                    <td style="text-align: right;">
                                      <img src="../../../../assets/images/Group@2x.png" />&nbsp;&nbsp;{{this.showLoyaltyUnitPrize(item2.loyalty_unit[0]) | number:'1.0-3':'de'}}
                                    </td>
                                    <td><img class="imagenProduct" [src]="item2.multimedia.mainImage[0].url" /></td>
                                    <td></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>

                <div class="hidden-desktop hidden-tablet display-mobile">
                    <div class="row justify-content-center">
                        <div class="col-12 mt-4 row justify-content-center">
                            <label class="iconTableStar font-weight-bold">Información de entrega</label>
                            <i [placement]="['right', 'bottom', 'auto']"
                                ngbPopover="(i) Si seleccionaste recogida en punto de venta, recuerda que te hemos enviado por email el código que deberás presentar para recoger el pedido antes de 30 días.
                                            (ii) Si seleccionaste envío a domicilio, recuerda que el plazo de envío será de 10 a 15 días."
                                class="fas fa-info-circle iconInfo"></i>
                        </div>
                    </div>

                    <!-- <div class="row mb-3">
                        <div class="col-12"><label class="iconTableStar font-weight-bold">Acciones disponibles</label>
                            <ng-template #actionsInfo>
                                <div class="mb-2">
                                    <i class="fa fa-reply-all iconAction info ml-0 mr-2" aria-hidden="true"></i>Reenvío
                                    de documentación
                                </div>
                                <div class="mb-2">
                                    <i class="fas fa-comment-dots iconAction info ml-0 mr-2"></i>Notificar incidencia o
                                    consulta
                                </div>
                                <div>
                                    <i class="fa fa-times-circle-o iconAction info ml-0 mr-2"
                                        aria-hidden="true"></i>Cancelar
                                </div>
                            </ng-template>
                            <i [placement]="['bottom']" [ngbPopover]="actionsInfo"
                                class="fas fa-info-circle iconInfo"></i>
                        </div>
                    </div> -->
                    <div class="row my-5 justify-content-center" *ngFor="let item of this.exchangesList;let i = index" data-toggle="collapse" [attr.href]="'#collapseExample2'+i">

                        <div class="row col-6 blue-text">
                            <div class="col-12 my-2">Nº pedido</div>
                            <div class="col-12 my-2">
                                Entrega
                                <i [placement]="['right', 'bottom', 'auto']"
                                    ngbPopover="(i) Si seleccionaste recogida en punto de venta, recuerda que te hemos enviado por email el código que deberás presentar para recoger el pedido antes de 30 días.
                                                (ii) Si seleccionaste envío a domicilio, recuerda que el plazo de envío será de 10 a 15 días." class="fas fa-info-circle iconInfo"></i>
                            </div>
                            <div class="col-12 my-2">
                                Estado
                                <ng-template #actionsInfo>
                                    <div class="mb-2">
                                        <i class="fa fa-reply-all iconAction info ml-0 mr-2" aria-hidden="true"></i>Reenvío de documentación
                                    </div>
                                    <div class="mb-2">
                                        <i class="fas fa-comment-dots iconAction info ml-0 mr-2"></i>Notificar incidencia o consulta
                                    </div>
                                    <div>
                                        <i class="fa fa-times-circle-o iconAction info ml-0 mr-2" aria-hidden="true"></i>Cancelar
                                    </div>
                                </ng-template>
                                <i [placement]="['right', 'bottom', 'auto']" [ngbPopover]="actionsInfo"
                                    class="fas fa-info-circle iconInfo hidden-desktop hidden-mobile"></i>
                            </div>
                            <div class="col-12 my-2">Acciones</div>
                        </div>

                        <div class="row col-6 mobile-table-td">
                            <div class="col-12 my-2">
                                {{ item.exchange_id }}
                            </div>
                            <div class="col-12 my-2">
                                {{ this.formatSiteExchange(item.where_ld)}}
                            </div>
                            <div class="col-12 my-2">
                                {{ this.formatDateExchange(item.exchange_date) }}
                            </div>
                            <div class="col-12 my-2">
                                <b>{{ item.status.message.es}}</b>
                                <ng-template #actionsInfo>
                                    <div class="mb-2">
                                        <i class="fa fa-reply-all iconAction info ml-0 mr-2" aria-hidden="true"></i>Reenvío de documentación
                                    </div>
                                    <div class="mb-2">
                                        <i class="fas fa-comment-dots iconAction info ml-0 mr-2"></i>Notificar incidencia o consulta
                                    </div>
                                    <div>
                                        <i class="fa fa-times-circle-o iconAction info ml-0 mr-2" aria-hidden="true"></i>Cancelar
                                    </div>
                                </ng-template>
                                <i [placement]="['right', 'bottom', 'auto']" [ngbPopover]="actionsInfo" class="fas fa-info-circle iconInfo"></i>
                            </div>
                            <div class="col-12 my-2 mt-2">
                                <div class="row">
                                    <!-- Boton de reenviar datos -->
                                    <div class="col" *ngIf="this.comprobarStatusReenviarOrder(i)">
                                        <i (click)="this.reSendDataExchange(item.lcpInteractionId)"
                                            class="fa fa-reply-all iconAction" aria-hidden="true"></i>
                                    </div>
                                    <div class="col" *ngIf="!this.comprobarStatusReenviarOrder(i)">
                                        <i class="fa fa-reply-all iconActionDisabled" aria-hidden="true"></i>
                                    </div>
                                    <!-- Boton de comentario -->
                                    <div class="col" *ngIf="this.comprobarStatusComment(i)">
                                        <i (click)="this.goToContact(item.reward_info[0][0].interaction_id)"
                                            class="fas fa-comment-dots iconAction"></i>
                                    </div>
                                    <div class="col" *ngIf="!this.comprobarStatusComment(i)">
                                        <i class="fas fa-comment-dots iconActionDisabled"></i>
                                    </div>
                                    <!-- Boton de cancelar -->
                                    <div class="col" *ngIf="this.comprobarStatusCancelOrder(i)">
                                        <i (click)="this.setExchangeCancel(item.exchange_id)" data-toggle="modal"
                                            data-target="#modalCancelacion" class="fa fa-times-circle-o iconAction"
                                            aria-hidden="true"></i>
                                    </div>
                                    <div class="col" *ngIf="!this.comprobarStatusCancelOrder(i)">
                                        <i class="fa fa-times-circle-o iconActionDisabled" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- show product hide -->
                        <div class="row col-12 mobile-table-td">
                            <div class="collapse collapseProducts row w-100 pl-3 my-3"
                                *ngFor="let item2 of this.formatArrayProductosPedido(item.reward_info);let x = index;" [id]="'collapseExample2'+i">
                                <div class="col-12 row justify-content-center">{{ item2.item_ld }}</div>
                                <div class="col-12 row justify-content-center">
                                    <img src="../../../../assets/images/Group@2x.png" class="mr-2" />
                                    {{this.showLoyaltyUnitPrize(item2.loyalty_unit[0]) | number:'1.0-3':'de'}}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="this.exchangesList.length == 0">
                <div class="alert alert-info">
                    Todavia no has canjeado ningún producto.
                </div>
            </div>
            <!-- <div class="col-md-3">
                <div class="row" *ngIf="this.exchangeDetail.name !== ''">
                    <div class="col-md-12">
                        <div class="card cardNaosPrevew">
                            <div class="card-header cardHeaderPreview">
                                {{this.exchangeDetail.name}}
                            </div>
                            <div class="card-body">
                                <p class="card-text">{{this.exchangeDetail.text}}</p>
                                <a class="btn btnEnviar">Enviar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>


<div class="modal fade" id="modalCancelacion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Cancelar pedido</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p>¿Estás seguro de que quieres cancelar este pedido?</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btnEnviarForms" [disabled]="this.buttonCancel" (click)="this.cancelExchange()">Confirmar
                    cancelación</button>
            </div>
        </div>
    </div>
</div>

<!--       <app-naosnotification [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationExchange" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 filaLogo">
                        <img src="../../../assets/images/LOGO-NaosClub.png" />
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12 filaLogo2" id="contenidodiv">
                        {{ this.textModal }}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="this.redirectPopupNotification()"
                    class="btn btn-secondary btnPopUpFormsCerrar" data-dismiss="modal" (click)="this.refreshCancelData()">Aceptar</button>
            </div>
        </div>
    </div>
</div>
