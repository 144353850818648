import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { ComponentsModule } from './components/components.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';
import { CanActivateLoginRegistarGuard } from './guards/login.guard';
import { NotifierModule } from 'angular-notifier';
import { FormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData, LocationStrategy, HashLocationStrategy } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
/* import { GtagModule } from 'angular-gtag'; */

registerLocaleData(localeDe, 'de');

/* const apiKeyBrand: string = (localStorage.getItem('brandweb') === 'BIODERMA') ? environment.APIKEYGEOCODINGBIODERMA : environment.APIKEYGEOCODINGESTHEDERM; */
const apiKeyBrand: string = environment.APIKEYMAPS;

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    PagesModule,
    ComponentsModule,
    HttpClientModule,
    NotifierModule,
    /* localStorage.getItem('brandweb') === 'ESTHEDERM' ? GtagModule.forRoot({ trackingId: 'AW-663548996', trackPageviews: true }) : GtagModule.forRoot({ trackingId: 'AW-756211765', trackPageviews: true }), */
    LoggerModule.forRoot({
      serverLoggingUrl: '/',
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false
    }),
    NgbModule,
    AgmCoreModule.forRoot ({
      apiKey: apiKeyBrand
  })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    CanActivateLoginRegistarGuard,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
