export class AppSettings {

  public static provincias = {
    'data': {
      'provinces': [
        { 'cdprovincia': '01', 'dcprovincia': 'ARABA/ÁLAVA' },
        { 'cdprovincia': '02', 'dcprovincia': 'ALBACETE' },
        { 'cdprovincia': '03', 'dcprovincia': 'ALICANTE/ALACANT' },
        { 'cdprovincia': '04', 'dcprovincia': 'ALMERÍA' },
        { 'cdprovincia': '05', 'dcprovincia': 'ÁVILA' },
        { 'cdprovincia': '06', 'dcprovincia': 'BADAJOZ' },
        { 'cdprovincia': '07', 'dcprovincia': 'BALEARS (ILLES)' },
        { 'cdprovincia': '08', 'dcprovincia': 'BARCELONA' },
        { 'cdprovincia': '09', 'dcprovincia': 'BURGOS' },
        { 'cdprovincia': '10', 'dcprovincia': 'CÁCERES' },
        { 'cdprovincia': '11', 'dcprovincia': 'CÁDIZ' },
        { 'cdprovincia': '12', 'dcprovincia': 'CASTELLÓN/CASTELLÓ' },
        { 'cdprovincia': '13', 'dcprovincia': 'CIUDAD REAL' },
        { 'cdprovincia': '14', 'dcprovincia': 'CÓRDOBA' },
        { 'cdprovincia': '15', 'dcprovincia': 'CORUÑA (A)' },
        { 'cdprovincia': '16', 'dcprovincia': 'CUENCA' },
        { 'cdprovincia': '17', 'dcprovincia': 'GIRONA' },
        { 'cdprovincia': '18', 'dcprovincia': 'GRANADA' },
        { 'cdprovincia': '19', 'dcprovincia': 'GUADALAJARA' },
        { 'cdprovincia': '20', 'dcprovincia': 'GIPUZKOA' },
        { 'cdprovincia': '21', 'dcprovincia': 'HUELVA' },
        { 'cdprovincia': '22', 'dcprovincia': 'HUESCA' },
        { 'cdprovincia': '23', 'dcprovincia': 'JAÉN' },
        { 'cdprovincia': '24', 'dcprovincia': 'LEÓN' },
        { 'cdprovincia': '25', 'dcprovincia': 'LLEIDA' },
        { 'cdprovincia': '26', 'dcprovincia': 'RIOJA (LA)' },
        { 'cdprovincia': '27', 'dcprovincia': 'LUGO' },
        { 'cdprovincia': '28', 'dcprovincia': 'MADRID' },
        { 'cdprovincia': '29', 'dcprovincia': 'MÁLAGA' },
        { 'cdprovincia': '30', 'dcprovincia': 'MURCIA' },
        { 'cdprovincia': '31', 'dcprovincia': 'NAVARRA' },
        { 'cdprovincia': '32', 'dcprovincia': 'OURENSE' },
        { 'cdprovincia': '33', 'dcprovincia': 'ASTURIAS' },
        { 'cdprovincia': '34', 'dcprovincia': 'PALENCIA' },
        { 'cdprovincia': '35', 'dcprovincia': 'PALMAS (LAS)' },
        { 'cdprovincia': '36', 'dcprovincia': 'PONTEVEDRA' },
        { 'cdprovincia': '37', 'dcprovincia': 'SALAMANCA' },
        { 'cdprovincia': '38', 'dcprovincia': 'TENERIFE' },
        { 'cdprovincia': '39', 'dcprovincia': 'CANTABRIA' },
        { 'cdprovincia': '40', 'dcprovincia': 'SEGOVIA' },
        { 'cdprovincia': '41', 'dcprovincia': 'SEVILLA' },
        { 'cdprovincia': '42', 'dcprovincia': 'SORIA' },
        { 'cdprovincia': '43', 'dcprovincia': 'TARRAGONA' },
        { 'cdprovincia': '44', 'dcprovincia': 'TERUEL' },
        { 'cdprovincia': '45', 'dcprovincia': 'TOLEDO' },
        { 'cdprovincia': '46', 'dcprovincia': 'VALENCIA/VALÈNCIA' },
        { 'cdprovincia': '47', 'dcprovincia': 'VALLADOLID' },
        { 'cdprovincia': '48', 'dcprovincia': 'BIZKAIA' },
        { 'cdprovincia': '49', 'dcprovincia': 'ZAMORA' },
        { 'cdprovincia': '50', 'dcprovincia': 'ZARAGOZA' }
      ]
    }
  };

  public static ppvvonlinepre = [
    {
      "where_id": 3553	,
      "where_cd": "F1025-10",
      "jwhere": {
        "where_ld": "MIFARMA"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 4607,
      "where_cd": "F1887-10",
      "jwhere": {
        "where_ld": "FARMACIA GÓMEZ-ULLA"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 5185,
      "where_cd": "F3153-10",
      "jwhere": {
        "where_ld": "FARMACIA SOLER"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 6244,
      "where_cd": "F3253-10",
      "jwhere": {
        "where_ld": "FARMACIA ARBOSANA"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 4505,
      "where_cd": "F3232-10",
      "jwhere": {
        "where_ld": "FARMACIA MARTA MASI"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 4786,
      "where_cd": "F1658-10",
      "jwhere": {
        "where_ld": "FARMACIA UNIVERSAL 24H"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 3805,
      "where_cd": "F2594-10",
      "jwhere": {
        "where_ld": "FARMACIA A5"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    }
  ];

  public static ppvvonlineprod = [
    {
      "where_id": 3553,
      "where_cd": "F1025-10",
      "jwhere": {
        "where_ld": "MIFARMA"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 4607,
      "where_cd": "F1887-10",
      "jwhere": {
        "where_ld": "FARMACIA GÓMEZ-ULLA"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 5185,
      "where_cd": "F3153-10",
      "jwhere": {
        "where_ld": "FARMACIA SOLER"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 3674,
      "where_cd": "F3253-10",
      "jwhere": {
        "where_ld": "FARMACIA ARBOSANA"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 4505,
      "where_cd": "F3232-10",
      "jwhere": {
        "where_ld": "FARMACIA MARTA MASI"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 4786,
      "where_cd": "F1658-10",
      "jwhere": {
        "where_ld": "FARMACIA UNIVERSAL 24H"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    },{
      "where_id": 3805,
      "where_cd": "F2594-10",
      "jwhere": {
        "where_ld": "FARMACIA A5"
      },
      "jwhereAddress": {
        "postal_cd": "",
      }
    }
  ];

  public static arrayJavaScriptEsthederm = [
    'https://www.esthederm.com/js/prototype/prototype.js',
    'https://www.esthederm.com/js/jquery/jquery-1.10.2.min.js',
    'https://www.esthederm.com/js/lib/jquery/noconflict.js',
    'https://www.esthederm.com/js/lib/ccard.js',
    'https://www.esthederm.com/js/prototype/validation.js',
    'https://www.esthederm.com/js/scriptaculous/builder.js',
    'https://www.esthederm.com/js/scriptaculous/effects.js',
    'https://www.esthederm.com/js/scriptaculous/dragdrop.js',
    'https://www.esthederm.com/js/scriptaculous/controls.js',
    'https://www.esthederm.com/js/scriptaculous/slider.js',
    'https://www.esthederm.com/js/varien/js.js',
    'https://www.esthederm.com/js/varien/form.js',
    'https://www.esthederm.com/js/mage/translate.js',
    'https://www.esthederm.com/js/mage/cookies.js',
    'https://www.esthederm.com/js/onibi/algolia-custom.js',
    'https://www.esthederm.com/js/onibi/wookmark.min.js',
    'https://www.esthederm.com/js/algoliasearch/internals/frontend/Function.prototype.bind.js',
    'https://www.esthederm.com/js/algoliasearch/internals/frontend/algoliaBundle.min.js',
    'https://www.esthederm.com/js/algoliasearch/internals/frontend/common.js',
    'https://www.esthederm.com/js/algoliasearch/autocomplete.js',
    'https://www.esthederm.com/skin/frontend/rwd/default/js/lib/modernizr.custom.min.js',
    'https://www.esthederm.com/skin/frontend/rwd/default/js/lib/selectivizr.js',
    'https://www.esthederm.com/skin/frontend/rwd/default/js/lib/matchMedia.js',
    'https://www.esthederm.com/skin/frontend/rwd/default/js/lib/matchMedia.addListener.js',
    'https://www.esthederm.com/skin/frontend/rwd/default/js/lib/enquire.js',
    'https://www.esthederm.com/skin/frontend/esthederm-world/default/js/app.js',
    'https://www.esthederm.com/skin/frontend/rwd/default/js/lib/jquery.cycle2.min.js',
    'https://www.esthederm.com/skin/frontend/rwd/default/js/lib/jquery.cycle2.swipe.min.js',
    'https://www.esthederm.com/skin/frontend/esthederm-world/default/js/slideshow.js',
    'https://www.esthederm.com/skin/frontend/rwd/default/js/lib/imagesloaded.js',
    'https://www.esthederm.com/skin/frontend/esthederm-world/default/js/minicart.js',
    'https://www.esthederm.com/skin/frontend/base/default/js/eucookielaw.js',
    'https://www.esthederm.com/skin/frontend/esthederm-world/default/js/onibi_ajaxnewsletter.js',
    'https://www.esthederm.com/skin/frontend/esthederm-world/default/js/esthederm.js',
    'https://www.esthederm.com/skin/frontend/esthederm-world/default/js/onestepcheckout.tweak.js',
    'https://www.esthederm.com/skin/frontend/esthederm-world/default/js/jcookies.js',
    'https://www.esthederm.com/skin/frontend/esthederm-world/default/js/owl-carousel/owl.carousel.min.js'
  ];

  // Servicio para obtener las coordenadas de una direccion utilizando nominatim para OSM
  public static RUTECOORDS = 'https://nominatim.openstreetmap.org';
  public static RUTECOORDS2 = 'https://nominatim.openstreetmap.org/';
  // Servicio para registro
  public static REGISTER = 'user/members';
  // Servicio para obtener los puntos de venta
  public static SHOPSITES = 'structure/geographical/public/wheres';
  // Servicio para obtener los puntos de venta online
  public static SHOPONLINE = 'structure/geographical/public/wheres';
  // Servicio para obtener los enroladores a partir de un ppvv
  public static ENROLERS = 'user/employees/wheres/public';
  // Servicio de cliente confirmación de email
  public static EMAILCONFIRM = 'user/members/mail-confirmation';
  // Servicio para hacer login
  public static LOGIN = 'user/login';
  // Servicio para recuperar contraseña (enviar email)
  public static REMEMBERPASSWORDSENDEMAIL = 'user/pre-restore-pwd';
  // Servicio para confirmar nueva contraseña
  public static REMEMBERPASSWORDNEWPASSWORD = 'user/restore-pwd';
  // Servicio para obtener los datos del cliente por id del cliente
  public static GETDATACLIENT = 'user/members/';
  // Servicio para obtener los datos de las direcciones del cliente por id del cliente
  public static GETDATADIRECTIONCLIENT = 'user/members/';
  // Servicio para modificar los datos del cliente
  public static UPDATEDATACLIENT = 'user/members/';
  // Servicio para dar de alta una dirección del cliente
  public static NEWDIRECTIONCLIENT = 'user/members/';
  // Servicio para dar de alta una direccion del cliente sin loguearse
  public static NEWDIRECTIONCLIENTPUBLIC = 'user/members/';
  // Servicio para modificar una dirección de un cliente
  public static UPDATEDATADIRECTIONCLIENT = 'user/members/';
  // Servicio para recibir los productos del catalogo
  public static GETPRODUCTS = 'structure/rewards/whats';
  // Servicio para recibir los productos del catalogo por cliente
  public static GETPRODUCTSCLIENT = 'structure/rewards/whats/segments/';
  // Servicio para recibir el detalle del producto
  public static GETPRODUCTDETAIL = 'structure/rewards/whats/';
  // Servicio para hacer el canje de productos
  public static SENDEXCHANGE = 'members/';
  // Servicio para obtener el listado de canjes de un cliente
  public static GETEXCHANGESLIST = 'user/members/';
  // Servicio para obtener los estado de un pedido
  public static GETSTATUSEXCHANGE = 'members/';
  // Servicio para cancelar un pedido de canje
  public static CANCELEXCHANGE = 'members/';
  // Servicio para obtener el saldo de estrellas del cliente
  public static GETSCORECLIENT = 'user/members/';
  // Serviciio para obtener los puntos caducados del usuario
  public static GETEXPIRATIONSCORE = 'user/members/';
  // Servicio para autocompletar la localidad
  public static AUTOCOMPLETELOCALITY = 'address/autocompletion/municipality';
  // Servicio para autocompletar la via por localdidad
  public static AUTOCOMPLETEVIA = 'address/autocompletion/street';
  // Servicio para autocompletar el numero por localidad y via
  public static AUTOCOMPLETENUMBER = 'address/autocompletion/number';
  // Servicio para eliminar la direccion de un cliente
  public static DELETEDIRECTIONCLIENT = 'user/members/';
  // Servicio para obtener los ids de las marcas para el filtro ya que cambian por entorno
  public static GETBRANDSIDS = 'structure/child?inUse=false&type=REWARD_PF';
  // Servicio para obtener los puntos cercanos al cliente para recoger productos
  public static GETPOINTSNEAR = 'structure/geographical/nearby/wheres/';
  // Servicio para obtener las coordenadas con el geocoder de google maps
  public static COORDSGOOGLE = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
  // Servicio para obtener los datos del histórico de canjes de mis compras
  public static GETHISTORYTICKETS = 'user/members/';
  // Servicio para reenviar los datos del pedido
  public static RESENDORDERDATA = 'user/members/';
  // Servicio para obtener los tickets subidos
  public static GETTICKETSLIST = 'interactions/standard';
  // Servicio para obtener los tipos de contacto del cliente
  public static GETTYPESCONTACT = 'communications/support/types';
  // Servicio para enviar el contacto
  public static SENDCONTACT = 'communications/support';
  // Servicio para obtener las marcas para el histórico
  public static GETBRANDS = 'structure/child?type=COMMERCIAL';
  // Servicio para dar de baja un usuario
  public static REMOVECOUNT = 'user/members/';
  // Servicio para obtener las razones de baja del cliente
  public static REASONSREMOVE = 'structure/reasons';
  // Servicio para obtener los test del cliente
  public static DATALISTTEST = 'external/skinTestList';
  // Servicio para obtener los test del cliente Tribba
  public static DATALISTTESTTRIBBA = 'user/members/data/';
  // Servicio para logs LSP
  public static LOGSLSP = 'external/log';
  // Servicio para obtener los banners
  public static GETBANNERS = 'structure/banners';
  // Servicio advserver privado
  public static ADVSERVERPRIVATE = 'communications/contents/customer/';
  // Servicio advserver publico
  public static ADSERVERPUBLIC = 'communications/contents/public';
  // Servicio para validar el código amigo introducido en el registro
  public static VALIDATEFRIENSCODE = 'user/members/publiccode';
  // Servicio para validar el código de landing
  public static CHECKCODELANDING = '/campaign/checkcode';
  // Servicio para obtener los test de la IA
  public static GETTESTIA = 'user/members/dataall/Revieve';
  // Servicio para Scanner IA despues del login
  public static SENDSCANNERIA = 'naos/saverevievetests';
  // Servicio para obtener el ratio de puntos de fidelización
  public static RATIONAOS = 'parameter/';
  // Servicio para dar de baja comercial desde el email
  public static SENDUNSUBSCRIBEEMAIL = 'user/members/unsubscribe';

  public static EMAILCONFIRMFAKE = 'http://ec2-18-159-192-71.eu-central-1.compute.amazonaws.com';

  // MEDIKTOR
  // Autenticación
  public static AUTENTICATIONMEDIKTOR = 'external/thirdpartylogin';
}
