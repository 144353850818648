import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ContactService } from 'src/app/services/contact.service';
import { DOCUMENT } from '@angular/common';
import { NAOSService } from 'src/app/services/naos.service';
import { LogService } from '@dagonmetric/ng-log';
declare var $: any;

@Component({
    selector: 'app-naos-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css'],
    providers: [UserService, ContactService, NAOSService, LogService]
})
export class ContactComponent implements OnInit {

    public typeRedirect: any;
    public idclient: any;
    public infoClient: any;
    public contErrors: number;
    public loader: boolean;
    public typesContact: any;
    public interactionId: any;
    public typesContactFormated: Array<any>;
    public email: string;
    public contact: string;
    public type: any;
    public asunto: string;
    public showImage: boolean;
    public sub: any;
    public typeDisabled: boolean;
    public textModal: string;
    public textUrl: string;
    public page:string;
    public clearComment: boolean;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private _routeActivated: ActivatedRoute,
        private _userService: UserService,
        private _contactService: ContactService,
        private _router: Router,
        private _naosService: NAOSService,
        private readonly logService: LogService
    ) {
        this.contErrors = 0;
        this.loader = false;
        this.typesContactFormated = [];
        this.type = '';
        this.asunto = '';
        this.contact = 'Escríbenos tu comentario...';
        this.showImage = false;
        this.interactionId = null;
        this.typeDisabled = false;
        this.page = "contact";
        this.clearComment = true;
    }

    ngOnInit(): void {

        this.logService.trackPageView({
            name: 'Contacto',
            uri: '/home/contact'
        });
        this.sub = this._routeActivated.paramMap.subscribe((params: ParamMap) => {
            this.typeRedirect = +params.get('id');
            if (this.typeRedirect == '1') {
                this.type = '2';
                this.typeDisabled = true;
            }
            if (this.typeRedirect == '2') {
                this.interactionId = null;
            }
            this.interactionId = +params.get('id2');
            if (this.interactionId == '0') {
                this.interactionId = null;
            }
        });

        if ( this.document.location.href.indexOf('home/store/contact') > -1) {
            this.showImage = false;
        } else {
            this.showImage = true;
        }

        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        this.getDataClient();
        this.getTypesContact();
        let intervaloError = setInterval(() => {
            if (this.contErrors >= 3) {
                this.loader = true;
                clearInterval(intervaloError);
            }
        }, 1000);

        setTimeout(() => {
          if (localStorage.getItem('faqs')) {
            $('#optcontact1').removeClass('selected');
            $('#optcontact2').addClass('selected');
            this.page = "questions";
            localStorage.removeItem('faqs');
          } else {
            $('#optcontact2').removeClass('selected');
            $('#optcontact1').addClass('selected');
          }
        },2000);


    }

    /**
     * Metodo para limpiar el campo comentario cuando tiene focus la primera vez
     * @param comentario Texto del input de comentario
     */
    public clearInput(comentario: any): void {
        if(this.clearComment && comentario === 'Escríbenos tu comentario...') {
          this.contact = '';
          this.clearComment = false;
        }
    }

    public goToHome(): void {
        this._router.navigate(['/home']);
    }

    /**
     * Funcion para volver atras
     */
    public goToAfter(): void {
        this._naosService.returnNavigationPageBack();
    }

    /**
     * Funcion para obtener los datos del cliente
     */
    public getDataClient(): void {
        this._userService.getDataClient(this.idclient).subscribe(
            response => {
                this.infoClient = response;
                this.email = this.infoClient.email;
                this.contErrors++;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
                this.contErrors++;
            }
        );
    }

    /**
     * Función para enviar el contacto
     */
    public sendContact(): void {
        if (this.email !== '' && this.type !== '' && this.contact !== '' && this.asunto !== '') {
            let obj = {
                'body': this.contact,
                'contactEmail': this.email,
                'interactionFatherId': this.interactionId,
                'memberId': this.idclient,
                'name': this.infoClient.name,
                'subject': this.asunto,
                'type': this.type
            };
            this._contactService.sendContact(obj).subscribe(
                () => {
                    this.textModal = 'Gracias por ponerte en contacto con NAOS Club. En breve recibirás respuesta de nuestro Servicio de Atención al Cliente.';
                    this.textUrl = '/home';
                    $('#exampleModalNotificationContact').modal('show');
                },
                () => {
                    this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                    this.textUrl = null;
                    $('#exampleModalNotificationContact').modal('show');
                }
            );
        } else {
            this.textModal = 'Por favor, rellena toda la información de tu consulta para que podamos atenderte adecuadamente.';
            this.textUrl = null;
            $('#exampleModalNotificationContact').modal('show');
        }
    }

    public goToExclusionList(): void {
        this._router.navigate(['/sitesnoauthorization']);
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }

    /**
     * Funcion para obtener los tipos de contacto
     */
    public getTypesContact(): void {
        this._contactService.getTypesContact().subscribe(
            response => {
                this.typesContact = response;
                this.formatTypesContact();
                this.contErrors++;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
                this.contErrors++;
            }
        );
    }


    /**
     * Funcion para crear un array del objeto que nos llega
     */
    public formatTypesContact(): void {
        let obj: any;
        let totalNum: number = 0;
        Object.keys(this.typesContact).forEach(key => {
            totalNum++;
        });
        for (let x = 0; x < totalNum; x++) {
            let propName = Object.keys(this.typesContact)[x];
            obj = {
                'type': this.typesContact[propName],
                'value': propName
            };
            this.typesContactFormated.push(obj);
        }
        this.contErrors++;
    }

     /**
     * Funcion que actua de submenu dentro de store
     * @param opt numero de opción sobre la que se ha echo clic
     */
    public clickOpt(opt: number): void {
        switch (opt) {
            case 1:
                $('#optcontact1').addClass('selected');
                $('#optcontact2').removeClass('selected');
                this.page = "contact";
                break;
            case 2:
                $('#optcontact2').addClass('selected');
                $('#optcontact1').removeClass('selected');
                this.page = "questions";
                break;
        }
    }
}
