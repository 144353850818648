<div class="container w-100 mw-100 mt-4">
    <div class="row justify-content-center mb-5">
        <div class="col-12 col-lg-3">
            <div class="form-group">
                <select (change)="this.filterProduct($event.target.value)" class="form-control selectpicker inputRegister input-select-naos">
                    <optgroup label="Estrellas">
                        <option value="Todos">Todos</option>
                        <option value="0">0 - 500</option>
                        <option value="500">500 - 1000</option>
                        <option value="1000">Más de 1000</option>
                    </optgroup>
                    <optgroup label="Marca">
                        <option value="bioderma">Bioderma</option>
                        <option value="esthederm">Esthederm</option>
                    </optgroup>
                </select>

            </div>
        </div>
        <div class="col-12 col-lg-3">
            <div class="form-group">
                <select (change)="this.orderFilter($event.target.value)" class="form-control inputRegister input-select-naos" id="ordenFiltro">
                    <option value='X'>Ordenar por</option>
                    <option value="asc">Nº Estrellas ascendente</option>
                    <option value="desc">Nº Estrellas descendente</option>
                </select>
            </div>
        </div>
        <div class="col-12 col-lg-3">
            <form class="input-group">
                <input class="form-control  inputRegister" (keyup)="this.searchNameProduct($event.target.value)" type="text" placeholder="Buscar" aria-label="Search">
                <i class="fas fa-search d-inline-flex align-self-center" style="color: silver;" aria-hidden="true"></i>
            </form>
        </div>
    </div>

    <div class="row loader" *ngIf="!this.loader">
        <div class="col-md-12">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>

    <div class="row mb-5" *ngIf="this.loader">
        <ng-container *ngIf="this.listadoProductos.length == 0 && this.loader">
            <div class="col-md-12">
                <div class="alert alert-info">
                    No tenemos productos cargados, disculpen las molestias.
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="this.listadoProductos.length > 0">
            <ng-container *ngFor="let item of this.listadoProductosFormated;let i = index;">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 containerProduct mb-4" *ngIf="this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) > 0">
                    <div class="card cardNaosProduct h-100">
                        <img (click)="this.goToViewDetail(item)" class="card-img-top imgNaosProduct titleOver m-auto" [src]="item.jwhat.product_info.multimedia.mainImage[1].url" [alt]="item.jwhat.item_sd">
                        <div class="card-body d-flex flex-column text-center">
                            <h5 (click)="this.goToViewDetail(item)" class="card-title titleOver">{{item.jwhat.product_info.item_sd.es}}</h5>
                            <p (click)="this.goToViewDetail(item)" class="card-text titleOver">
                                <img src="../../../assets/images/Group.png" /> {{this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) | number:'1.0-3':'de'}} estrellas ({{this.convertStarsToEuros(this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units))}} €)
                            </p>
                            <a (click)="this.addProductToBag(item)" class="btn btnNaosProduct mt-auto">Añadir a la cesta</a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>

<!-- <app-naosnotification [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationCatalogo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 filaLogo">
                <img src="../../../assets/images/LOGO-NaosClub.png" />
            </div>
          </div><br>
          <div class="row">
              <div class="col-md-12 filaLogo2" id="contenidodiv">
                {{ this.textModal }}
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
</div>


<div class="modal fade" id="ModalCatalogoItem" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">

        <div class="col-md-12 pt-1 pl-2 mb-3">
            <div class="text-rigth">
                <button type="button" class="close modal-close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>

        <div class="modal-body row pl-2 pr-5 pt-0">
            <div class="col-md-6 p-2">
                <img class="card-img-top imgNaosProductModal titleOver p-2" [src]="this.textUrl" [alt]="this.modalImgAlt">
            </div>

            <div class="col-md-6 card-body d-flex flex-column text-center">
                <h5 class="blue-text card-title titleOver"> {{this.modalTitle}}</h5>

                <hr>
                <p style="color: silver; margin: 0px;" [innerHTML]="this.modalItemDetail"></p>
                <hr>

                <p class="card-text titleOver mt-auto p-2">
                    <img src="../../../assets/images/Group.png" />
                    {{this.numberStartsModal | number:'1.0-3':'de'}} estrellas <br> ({{this.convertStarsToEuros(this.numberStartsModal)}} €)
                </p>
                <div class="px-2 mt-auto">
                    <a (click)="this.addProductToBagModal(this.modalItem)" class="btn btnNaosProduct mt-auto">Añadir a la cesta</a>
                </div>

            </div>

        </div>
      </div>
    </div>
</div>
