import { Component, OnInit } from "@angular/core";
import { TransactionService } from "src/app/services/transactions.service";
import { NAOSService } from "src/app/services/naos.service";
import { ExchangesService } from "src/app/services/exchanges.service";
import { BuyService } from "src/app/services/buy.service";
import { Router } from "@angular/router";
import { ConditionalExpr } from "@angular/compiler";
import { LogService } from '@dagonmetric/ng-log';
import { Observable } from 'rxjs';
declare var $: any;

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.css"],
  providers: [TransactionService, NAOSService, ExchangesService, BuyService, LogService],
})
export class HistoryComponent implements OnInit {
  public buyList: Array<any>;
  public exchangesList: Array<any>;
  public buyListPlus: Array<any>;
  public arrayData: Array<any>;
  public idclient: any;
  public urlImageTicket: string;
  public brandsList: Array<any>;

  public loader: boolean;

  public dateIni: any;
  public dateFin: any;
  public contloader: number;
  public listCanceled: Array<any>;
  public textModal: string;
  public textUrl: string;

  public dateMinlimit: any;
  public dateMaxlimit: any;

  constructor(
    private _transactionService: TransactionService,
    public _naosService: NAOSService,
    private _exchangesService: ExchangesService,
    private _buyService: BuyService,
    private _router: Router,
    private readonly logService: LogService
  ) {
    this.loader = true;
    this.buyList = [];
    this.buyListPlus = [];
    this.contloader = 0;
    this.arrayData = [];
    this.exchangesList = [];
    this.urlImageTicket = undefined;
    this.dateIni = this._naosService.getThreeMonthLater();
    this.dateFin = this._naosService.getDateShortyyyyMMdd();
    this.dateMinlimit = this._naosService.getLimitDateHistory();
    this.listCanceled = [];
    this.brandsList = [];
  }

  ngOnInit(): void {
    this.logService.trackPageView({
      name: 'Histórico compras',
      uri: '/home/transactions/history'
    });
    this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));

    // Cambiar nombre
    this.getDataList();
  }

  /**
   * Return value rewards
   */
  async getDataList(): Promise<any> {
    this.loader = true;
    await this.getDataExchangesAll();
    this.loader = false;
  }

  /**
     * Metodo para coger solamente las unidades de fidelización con id 7
     * @param arrayUnit array de unidades de fidelización del producto
     */
    public showLoyaltyUnitPrize(arrayUnit: Array<any>): number {
      let prize: number = 0;
      for (let item of arrayUnit) {
        if (item.loyalty_unit_id == 7) {
          prize = item.loyalty_unit_quantity;
        }
      }
      return prize;
    }

  public getDataExchangesAll(): void {
      this._exchangesService.getExchangesAll(this.idclient, this.dateIni, this.dateFin).subscribe(
        (response) => {
          /* this.arrayData = response; */
          for (let item2 of response) {
            if (item2.description == null || item2.description == 'null') {
              item2.description = {};
              item2.description.es = '';
            }
          }
          for (let item of response) {
            if (this.showLoyaltyUnitPrize(item.loyalty_unit) !== 0) {
              this.arrayData.push(item);
            }
          }
          this.arrayData.sort(
            (a, b) => <any>new Date(b.activity_date_string) - <any>new Date(a.activity_date_string)
          );
          // Controlamos si el campo description viene null
          /* for (let item of this.arrayData) {
            if (item.description == null) {
              item.description.es = '';
            }
          } */
          this.loader = false;
        },
        (error) => {
          if (error.error.code === '400.2') {
            this.contloader++;
            this.textModal =
              "No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.";
            this.textUrl = null;
            $("#exampleModalNotificationHistory").modal("show");
            this.loader = false;
            this.arrayData = [];
          } else {
            this.contloader++;
            this.textModal =
              "No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.";
            this.textUrl = null;
            $("#exampleModalNotificationHistory").modal("show");
            this.loader = false;
            this.arrayData = [];
          }
        }
      );
  }

  /**
   * Funcion para obtener el listado de canjes por cliente
   */
  public getExchanges(): Promise<any> {
    return new Promise((resolve, reject) => {
    this._exchangesService
      .getExchangesClient(this.idclient, this.dateIni, this.dateFin)
      .subscribe(
        (response) => {
          this.exchangesList = response;
          this.arrayData = this.concatArrayData(response);
          this.formatArrayComplete(this.arrayData);
          this.arrayData.sort(
            (a, b) => <any>new Date(b.fechanaos) - <any>new Date(a.fechanaos)
          );
          this.contloader++;
          resolve(undefined);
        },
        (error) => {
          this.contloader++;
          this.textModal =
            "No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.";
          this.textUrl = null;
          $("#exampleModalNotificationHistory").modal("show");
          this.loader = false;
          this.arrayData = [];
          reject();
        }
      );
    });
  }

  public redirectPopupNotification(): void {
    if (this.textUrl !== null) {
      this._router.navigate([this.textUrl]);
    }
  }

  /**
   * Funcion para recibir las compras del cliente
   * @param id del cliente
   */
  public getListBuys(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._transactionService
        .getListTransactions(this.idclient, this.dateIni, this.dateFin)
        .subscribe(
          (response) => {
            this.buyList = response;
            this.contloader++;
            resolve(undefined);
          },
          (error) => {
            console.error("Error" + JSON.stringify(error));
            this.contloader++;
            this.textModal =
            "No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.";
            this.textUrl = null;
            $("#exampleModalNotificationHistory").modal("show");
            this.loader = false;
            this.arrayData = [];
            reject();
          }
        );
    });
  }



  /**
   * Funcion para obtener los tickets subidos
   */
  public getExchangesCancel(): Promise<any> {
    return new Promise((resolve, reject) => {
    this._buyService
      .getTicketsListCancel(this.idclient, this.dateIni, this.dateFin)
      .subscribe(
        (response) => {
          for (let item of response.content) {
            for (let x = 0; x < item.childs.length; x++) {
              if (item.childs[x].jsonType == "JCANCEL_EXCHANGE") {
                item.cancel = true;
                item.fechanaos =
                  item.childs[x].jinteraction.request.cancellation_date;
                item.exchangeid =
                  item.childs[x].jinteraction.request.exchange_id;
                item.stars =
                  item.childs[x + 1].jinteraction.loyalty_unit_quantity;
                item.productname = item.childs[x + 1].jinteraction.item_sd;
                this.listCanceled.push(item);
              }
            }
          }
          this.contloader++;
          resolve(undefined);
        },
        () => {
          this.textModal =
            "No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.";
          this.textUrl = null;
          $("#exampleModalNotificationHistory").modal("show");
          this.loader = false;
          this.arrayData = [];
          this.contloader++;
          reject();
        }
      );
    });
  }

  /**
   * Funcion para concatenar los arrays
   */
  public concatArrayData(response: Array<any>): Array<any> {
    let arrayaux = this.buyList.concat(response);
    for (let item of arrayaux) {
      if (item.reward_info == undefined) {
        // item.fechanaos = item.purchase_date;
        item.fechanaos = item.purchase_fulldate;
      } else {
        item.fechanaos = item.exchange_date;
      }
    }
    return arrayaux;
  }

  /**
   * Funcion para formatear el array completo
   */
  public formatArrayComplete(arrayAll: Array<any>): void {
    let arrayAux: Array<any> = [];
    this.arrayData = [];
    for (let item of arrayAll) {
      if (item.loyalty_unit_id !== undefined) {
        if (item.loyalty_unit_id === 7 && parseInt(item.reward) > 0) {
          arrayAux.push(item);
        }
      } else if (
        item.reward_info[0][0].loyalty_unit[0][0].loyalty_unit_id !== undefined
      ) {
        if (
          item.reward_info[0][0].loyalty_unit[0][0].loyalty_unit_id === 7 &&
          parseInt(
            item.reward_info[0][0].loyalty_unit[0][0].loyalty_unit_quantity
          ) > 0
        ) {
          arrayAux.push(item);
        }
      }
    }
    this.arrayData = [];
    this.arrayData = arrayAux;
    let arrayAuxConcat: Array<any> = this.arrayData.concat(this.listCanceled);
    this.arrayData = arrayAuxConcat;
    this.contloader++;
  }

  /**
   * Funcion para ver la vista previa del ticket
   * @param url dirección de la imagen del ticket
   */
  public viewPreview(url: string): void {
    this.urlImageTicket = url;
  }

  /**
   * Funcion para buscar y cambiar la fecha de inicio del filtro
   * @param dateIni fecha inicio del filtro
   */
  public setFilterValuesIni(dateIni: any): void {
    this.dateIni = dateIni;
    this.arrayData = [];
    this.getDataList();
  }

  /**
   * Funcion para buscar y cambiar la fecha de fin del filtro
   * @param dateFin fecha fin del filtro
   */
  public setFilterValuesFin(dateFin: any): void {
    this.dateFin = dateFin;
    this.arrayData = [];
    this.getDataList();
  }
}
