import { Component, OnInit, HostListener, Input } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NAOSService } from 'src/app/services/naos.service';
import { environment } from 'src/environments/environment';
import { LogService } from '@dagonmetric/ng-log';
declare var $: any;

@Component({
    selector: 'app-naos-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [LoginService, NAOSService, LogService]
})
export class LoginComponent implements OnInit {

    public email: string;
    public password: string;
    public showpassword: boolean;
    public key: any;
    public textModal: string;
    public textUrl: string;
    @Input() disabledButton: boolean;
    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.key = event.key;
        if (this.key === 'Enter') {
            this.doLogin();
        }
    }

    constructor(
        private _loginService: LoginService,
        private _router: Router,
        private _cookieService: CookieService,
        private _naosService: NAOSService,
        private readonly logService: LogService
    ) {
        this.showpassword = false;
        this.disabledButton = false;
        // this.textModal = "No ha sido posible iniciar sesión. Por favor, revisa el usuario/contraseña introducidos2";
    }

    ngOnInit(): void {
        this.logService.trackPageView({
            name: 'Login',
            uri: '/login'
        });

        $(document).ready(function() {
          $("#show_hide_password a").on('click', function(event) {
              event.preventDefault();
              if($('#show_hide_password input').attr("type") == "text"){
                  $('#show_hide_password input').attr('type', 'password');
                  $('#show_hide_password i').addClass( "fa-eye-slash" );
                  $('#show_hide_password i').removeClass( "fa-eye" );
              }else if($('#show_hide_password input').attr("type") == "password"){
                  $('#show_hide_password input').attr('type', 'text');
                  $('#show_hide_password i').removeClass( "fa-eye-slash" );
                  $('#show_hide_password i').addClass( "fa-eye" );
              }
          });
      });
    }

    ngOnDestroy(): void {
        localStorage.removeItem('redirectValue');
    }

    /**
     * Funcion para enviar los datos del login
     */
    public doLogin(): void {
        if (this.email !== undefined && this.password !== undefined) {
            if (this.validateEmail()) {
                const obj = {
                    'companyId': 4,
                    'pwd': this.password,
                    'type': 3,
                    'user':  this.email
                };
                this._loginService.sendLogin(obj).subscribe(
                    response => {
                        sessionStorage.setItem('ILCK', this._naosService.encryptData(response.customerId));
                        sessionStorage.setItem('NKT', response.token);

                        this._loginService.sendScannerIAData(response.customerId).subscribe(
                          response => {},
                          error => {
                            console.error('Error ' + JSON.stringify(error));
                          }
                        );

                        localStorage.setItem('NIN', this._naosService.encryptData("naosclub"+response.customerId));
                        if (localStorage.getItem('redirectValue') !== undefined && localStorage.getItem('redirectValue') !== null) {
                            this._naosService.redirectLinks(localStorage.getItem('redirectValue'));
                            localStorage.removeItem('redirectValue');
                        } else {
                            this._router.navigate(['home']);
                            localStorage.removeItem('redirectValue');
                        }
                    },
                    error => {
                        console.error('Error' + error.error.code);
                        if (error.error.code == '401.1') {
                            this.textModal = 'No ha sido posible iniciar sesión. Por favor, revisa el usuario/contraseña introducidos.';
                            this.textUrl = null;
                            $('#exampleModalNotificationLogin').modal('show');
                        } else if (error.error.code == '404.3') {
                            this.textModal = 'Esta cuenta ha sido dada de baja. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com';
                            this.textUrl = null;
                            $('#exampleModalNotificationLogin').modal('show');
                        } else if (error.error.code == '403.20') {
                            this.textModal = 'Para iniciar sesión es necesario que cambies la contraseña. Para ello utiliza la opción "He olvidado mi contraseña';
                            this.textUrl = null;
                            $('#exampleModalNotificationLogin').modal('show');
                        } else if (error.error.code == '403.22') {
                            this.textModal = 'Usuario bloqueado. Para iniciar sesión es necesario que cambies la contraseña. Pulsa "He olvidado mi contraseña" para generar una nueva.';
                            this.textUrl = null;
                            $('#exampleModalNotificationLogin').modal('show');
                        } else if (error.error.code == '409.24') {
                            this.textModal = 'No ha sido posible realizar el registro. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com. Recuerda que si ya te has registrado previamente debes activar tu cuenta confirmando tu registro en el email que te hemos enviado. No olvides revisar tu bandeja de spam o de correo no deseado.';
                            this.textUrl = null;
                            $('#exampleModalNotificationLogin').modal('show');
                        } else {
                            this.textModal = 'No ha sido posible iniciar sesión. Por favor, revisa el usuario/contraseña introducidos.';
                            this.textUrl = null;
                            $('#exampleModalNotificationLogin').modal('show');
                        }
                    }
                );
            } else {
                this.textModal = 'Por favor, introduce un email válido.';
                this.textUrl = null;
                $('#exampleModalNotificationLogin').modal('show');
            }
        } else {
            this.textModal = 'Por favor, rellena los campos obligatorios para continuar.';
            this.textUrl = null;
            $('#exampleModalNotificationLogin').modal('show');
        }
    }

    /**
     * Funcion para ver la contraseña
     * @param value valor del checkbox
     */
    public showPassword() {
        if (!this.showpassword) {
            this.showpassword = true;
        } else {
            this.showpassword = false;
        }
    }

    /**
     * Funcion para recuperar la contraseña
     */
    public rememberPassword(): void {
        if (this.email !== '' && this.email !== undefined) {
            let obj = {
                'companyId': 4,
                'type': 3,
                'user': this.email
              };
            this._loginService.rememberPassword(obj).subscribe(
                response => {
                    localStorage.setItem('remember', 'yes');
                    this.textModal = 'Revisa tu bandeja de entrada. Te hemos enviado un email con las instrucciones para cambiar tu contraseña.';
                    this.textUrl = null;
                    $('#exampleModalNotificationLogin').modal('show');
                },
                error => {
                    if (error.error.code == '404' || error.error.code == '404.3') {
                        this.textModal = 'Lo sentimos. No hemos podido identificar tu cuenta con el email facilitado. Recuerda que debes informar el email con el que estás registrado en el NAOS Club.';
                        this.textUrl = null;
                        $('#exampleModalNotificationLogin').modal('show');
                    } else {
                        this.textModal = 'No se ha podido recuperar la contraseña. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com';
                        this.textUrl = null;
                        $('#exampleModalNotificationLogin').modal('show');
                    }
                }
            );
        } else {
            this.textModal = 'Debes informar el email con el que estás registrado en el NAOS Club.';
            this.textUrl = null;
            $('#exampleModalNotificationLogin').modal('show');
        }
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }

    /**
     * Funcion para ir al registro desde el login
     */
    public goToRegister(): void {
        this._router.navigate(['register']);
    }

    /**
     * Funcion para validar email
     */
    public validateEmail(): boolean {
        let valido: boolean = true;
        if (environment.VALIDATIONEMAIL) {
            if (/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/.test(this.email)) {
                valido = true;
            } else {
                valido = false;
            }
        }
        return valido;
    }
}
