import { environment } from './../../../environments/environment.prod';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MapNaosComponent } from './map.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';

/* const apiKeyBrand: string = (localStorage.getItem('brandweb') === 'BIODERMA') ? environment.APIKEYGEOCODINGBIODERMA : environment.APIKEYGEOCODINGESTHEDERM; */
const apiKeyBrand: string = environment.APIKEYMAPS;

@NgModule({
    declarations: [ MapNaosComponent ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        NaosNotificationModule,
        HttpClientModule,
        AgmCoreModule.forRoot ({ apiKey: apiKeyBrand})
    ],
    exports: [ MapNaosComponent ],
    providers: [],
})
export class MapNAOSModule {}
