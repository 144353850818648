<div class="row p-3 text-center firstpart">
  <div class="col-12">
    <div class="row lineatexto">
      <div class="col-12">
          <h3>Tu piel, nuestra razón de ser</h3>
      </div>
    </div>
    <div class="row text-center lineaiconos">
      <div class="col-lg-3 col-md-3 col-xs-12 mb-5">
        <div class="row"><div class="col-12"><img class="iconosFooterBioderma" src="../../../assets/images/icono1bioderma.svg"/></div></div>
        <div class="row"><div class="col-12"><p class="textosIconos">Junto con profesionales de la salud desde hace 40 años</p></div></div>
        <div class="row"><div class="col-12"><a class="enlaceIconos" href="https://www.bioderma.es/la-empresa/biologia-al-servicio-de-la-dermatologia">Descubre nuestro asesoramiento dermatológico</a></div></div>
      </div>
      <div class="col-lg-3 col-md-3 col-xs-12 mb-5">
          <div class="row"><div class="col-12"><img class="iconosFooterBioderma" src="../../../assets/images/icono2bioderma.svg"/></div></div>
          <div class="row"><div class="col-12"><p class="textosIconos">Un profundo respeto de la piel</p></div></div>
          <div class="row"><div class="col-12"><a class="enlaceIconos" href="https://www.bioderma.es/la-empresa/bioderma-la-marca-dermatologica-de-naos">Descubre nuestra estrategia ecobiológica</a></div></div>
      </div>
      <div class="col-lg-3 col-md-3 col-xs-12 mb-5">
          <div class="row"><div class="col-12"><img class="iconosFooterBioderma" src="../../../assets/images/icono3bioderma.svg"/></div></div>
          <div class="row"><div class="col-12"><p class="textosIconos">Eficacia y seguridad probadas bajo control médico</p></div></div>
          <div class="row"><div class="col-12"><a class="enlaceIconos" href="https://www.bioderma.es/la-empresa/nuestros-compromisos-al-servicio-de-la-salud-de-la-piel">Más información acerca de nuestros estudios clínicos</a></div></div>
      </div>
      <div class="col-lg-3 col-md-3 col-xs-12 mb-5">
          <div class="row"><div class="col-12"><img class="iconosFooterBiodermaAsk" src="../../../assets/images/asklogo.PNG"/></div></div>
          <div class="row"><div class="col-12"><p class="textosIconos">Transparencia total en los ingredientes y composición de nuestros productos para el cuidado de la piel.</p></div></div>
          <div class="row"><div class="col-12"><a class="enlaceIconos" href="https://www.bioderma.es/la-empresa/nuestros-compromisos-al-servicio-de-la-salud-de-la-piel">Descifra los ingredientes de nuestros productos</a></div></div>
      </div>
    </div>
  </div>
</div>
<div class="row p-3 text-center secondpart">
  <div class="col-12">
    <div class="row lineaiconos text-center">
      <div class="col-lg-4 col-md-6 col-xs-6 colresponsivelinks">
        <div class="row rowayudaresponsive">
          <div class="col-lg-2 col-md-2 col-xs-2" style="width: 20%;">
            <img class="iconosFooterBiodermaAnswer" src="../../../assets/images/answerblancobioderma.PNG"/>
          </div>
          <div class="col-lg-10 col-md-2 col-xs-2 textoenlaceayuda" style="width: 40%;">
            AYUDA Y CONTACTO
          </div>
        </div>
        <div class="row">
          <div class="col-12 enlacelinks">
            <u>
              <a class="enlaceIconos" href="https://www.bioderma.es/contacta-con-nosotros">Contacta con nosotros</a>
            </u>
          </div>
        </div>

      </div>
      <div class="col-lg-4 col-md-6 col-xs-6 responsiveicon2">
        <div class="row rowayudaresponsive">
          <div class="col-lg-2 col-md-2 col-xs-2" style="width: 20%;">
            <img class="iconosFooterBioderma" src="../../../assets/images/icono5bioderma.png"/>
          </div>
          <div class="col-lg-10 col-md-2 col-xs-2 textoenlaceayuda" style="width: 40%;">
              CONSIGUE REGALOS CUIDANDO TU PIEL.
          </div>
        </div>
        <div class="row">
            <div class="col-12 enlacelinks">
                <u><a class="enlaceIconos" href="https://naosclub.bioderma.es">¡Únete a nuestro NAOS Club!</a></u>
            </div>
        </div>

      </div>
      <div class="col-lg-4 col-md-12 col-xs-12">
          <div class="row rowrrssresponsive">
            <div class="col-12">
                Síguenos en Redes Sociales
            </div>
          </div>
          <div class="row rowrrssresponsiveicons">
            <div class="col-2">
                <a class="pl-1" href="https://www.facebook.com/BIODERMAEspana/"><div class="circuloBlancoRRSS"><img class="rrssBioderma" src="../../../assets/images/iconoFacebookBioderma.svg"/></div></a>
            </div>
            <div class="col-2">
                <a class="pl-1" href="https://www.youtube.com/channel/UCru-RtpXlJbFm_miDwFN7_Q"><div class="circuloBlancoRRSS"><img class="rrssBioderma" src="../../../assets/images/iconoYoutubeBioderma.svg"/></div></a>
            </div>
            <div class="col-2">
                <a class="pl-1" href="https://www.instagram.com/bioderma_es/"><div class="circuloBlancoRRSS"><img class="rrssBioderma" src="../../../assets/images/iconoInstagramBioderma.svg"/></div></a>
            </div>
            <div class="col-2">
                <a class="pl-1" href="https://twitter.com/BIODERMA_es"><div class="circuloBlancoRRSS"><img class="rrssBioderma" src="../../../assets/images/iconoTwiterBioderma.svg"/></div></a>
            </div>
          </div>
      </div>
    </div>
    <div class="row" style="padding-bottom: 3%;">
      <div class="col-lg-6 col-md-12 col-xs-12 colbiodermatext">
        <p>BIODERMA es una marca de NAOS. La ecobiología está en el corazón de la estrategia de NAOS que tiene como misión respetar el ecosistema de la piel y preservar su salud de forma duradera.<br><a href="https://naos.com/es/">www.naos.com</a></p>
      </div>
      <div class="col-lg-6 col-md-12 col-xs-12">
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-lg-6 col-md-12 col-xs-12">
          Care first. <a class="enlaceIconos" href="https://naos.com/es/"><img class="iconoNaosBioderma" src="../../../assets/images/icono6bioderma.png"/></a>
      </div>
      <div class="col-lg-6 col-md-12 col-xs-12">

      </div>
    </div>
    <div class="row" style="padding-top: 2%;padding-bottom: 5%;">
      <div class="col-lg-6 col-md-12 col-xs-12 colbiodermatext">
        <a class="enlaceIconos" href="https://www.bioderma.es/condiciones-de-uso-de-la-web">Condiciones de uso</a> | <a class="enlaceIconos" href="https://www.bioderma.es/informacion-sobre-proteccion-de-datos-bioderma-1a-capa">
          Política de privacidad</a> | <a class="enlaceIconos" href="https://www.bioderma.es/politica-de-cookies-de-wwwbiodermaes">Política de cookies</a> | <a class="enlaceIconos" href="https://www.bioderma.es/">Configuración de cookies</a> | <a class="enlaceIconos" href="/privacitynew">NAOS CLUB Política de privacidad</a> | <a class="enlaceIconos" href="/legals">NAOS CLUB Condiciones Generales de Adhesión</a>
      </div>
      <div class="col-lg-6 col-md-12 col-xs-12">

      </div>
    </div>
  </div>
</div>
<div class="row p-3 text-center footercontact hide-on-desktop">
  <div class="col-12">
    <a data-toggle="collapse" href="#multiCollapseExample1" aria-expanded="false" aria-controls="multiCollapseExample1" (click)="this.scrolltomove()">
        <span class="iwt" style="color: white">
            <i class="fa fa-comment-o" aria-hidden="true"></i>&nbsp;
            <span style="color: white;">CONTACT</span>
          </span>
    </a>
  </div>
</div>
<div class="row collapse footercontact2" id="multiCollapseExample1">
  <div class="col-12">
    <div class="row text-center" style="border-bottom: 1px solid white;padding: 5%;">
      <div class="col-12" style="color: white">¿Preguntas sobre tu piel?</div>
    </div>
    <div class="row" style="text-align: left !important;color: white !important;padding: 5%;">
      <div class="col-12">
          <a target="_blank" style="color: white !important" href="https://www.facebook.com/BIODERMAEspana/"><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;CHAT DE MESSENGER</a>
      </div>
    </div>
    <div class="row" style="text-align: left !important;color: white !important;padding: 5%;">
        <div class="col-12">
            <a target="_blank" style="color: white !important" href="https://www.bioderma.es/contacta-con-nosotros"><i class="fa fa-envelope-open-o" aria-hidden="true"></i>&nbsp;EMAIL</a>
        </div>
      </div>
  </div>
</div>
