<ng-container *ngIf="this.initbrand == 'bioderma'">
  <app-navbarbioderma></app-navbarbioderma>
</ng-container>
<ng-container *ngIf="this.initbrand == 'esthederm'">
  <app-navbaresthederm></app-navbaresthederm>
</ng-container>

<router-outlet></router-outlet>

<ng-container *ngIf="this.initbrand == 'bioderma'">
  <app-footer-bioderma></app-footer-bioderma>
</ng-container>
<ng-container *ngIf="this.initbrand == 'esthederm'">
  <app-footer-esthederm></app-footer-esthederm>
</ng-container>

<notifier-container></notifier-container>


<!-- <div class="modal" id="modalRegisterRed" style="z-index: 2060" role="dialog" aria-labelledby="modalRegisterRed" aria-hidden="true">
    <div class="modal-dialog   " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalRegisterRed">Authentication</h5>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="user">Usuario</label>
                    <input type="text" [(ngModel)]="this.user" class="form-control" id="user">
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" [(ngModel)]="this.password" class="form-control" id="password">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="this.loginRed()" class="btn btn-primary">Entrar</button>
            </div>
        </div>
    </div>
</div> -->

<ng-template #modalMaintenance let-modal tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12 filaLogo text-center">
            <img src="../../../assets/images/LOGO-NaosClub.png" />
        </div>
      </div><br>
      <div class="row">
          <div class="col-md-12 filaLogo2 text-center" id="contenidodiv">
            Estamos en mantenimiento
          </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-dismiss="modal">Entendido</button>
    </div>
  </div>
</ng-template>
