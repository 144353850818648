import { Component, OnInit } from '@angular/core';
declare var $ : any;

@Component({
  selector: 'app-footer-bioderma',
  templateUrl: './footer-bioderma.component.html',
  styleUrls: ['./footer-bioderma.component.css']
})
export class FooterBiodermaComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }

  public scrolltomove(): void {
    setTimeout(()=> {
      window.scrollTo(0,document.body.scrollHeight);
    },250);

  }
}
