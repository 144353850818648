import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LogService } from '@dagonmetric/ng-log';


@Injectable()
export class LogsTrackService {

    constructor(
        private readonly logService: LogService,
    ) {

    }

    public setTrackView(name: string, url: string): void {
        this.logService.trackPageView({
            name: name,
            uri: url
        });
    }


}
