import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LogService } from '@dagonmetric/ng-log';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-rememberpassword',
    templateUrl: './rememberpassword.component.html',
    styleUrls: ['./rememberpassword.component.css'],
    providers: [LoginService, LogService]
})
export class RememberPasswordComponent implements OnInit {

    public password: string;
    public repeatpassword: string;
    public sub: any;
    public token: any;
    public showpassword: boolean;
    public textModal: string;
    public textUrl: string;

    public loader: boolean;

    public uuid: any;

    constructor(
        private _loginService: LoginService,
        private route: ActivatedRoute,
        private _router: Router,
        private readonly logService: LogService
    ) {
        this.showpassword = false;
        this.textUrl = null;
        this.loader = false;
        this.uuid = uuidv4();
    }

    ngOnInit(): void {
        this.logService.trackPageView({
            name: 'Recordar contraseña',
            uri: '/rememberpassword'
        });
        $(document).ready(function() {
          $("#show_hide_password a").on('click', function(event) {
              event.preventDefault();
              if($('#show_hide_password input').attr("type") == "text"){
                  $('#show_hide_password input').attr('type', 'password');
                  $('#show_hide_password i').addClass( "fa-eye-slash" );
                  $('#show_hide_password i').removeClass( "fa-eye" );
              }else if($('#show_hide_password input').attr("type") == "password"){
                  $('#show_hide_password input').attr('type', 'text');
                  $('#show_hide_password i').removeClass( "fa-eye-slash" );
                  $('#show_hide_password i').addClass( "fa-eye" );
              }
          });
      });
      $(document).ready(function() {
        $("#show_hide_password1 a").on('click', function(event) {
            event.preventDefault();
            if($('#show_hide_password1 input').attr("type") == "text"){
                $('#show_hide_password1 input').attr('type', 'password');
                $('#show_hide_password1 i').addClass( "fa-eye-slash" );
                $('#show_hide_password1 i').removeClass( "fa-eye" );
            }else if($('#show_hide_password1 input').attr("type") == "password"){
                $('#show_hide_password1 input').attr('type', 'text');
                $('#show_hide_password1 i').removeClass( "fa-eye-slash" );
                $('#show_hide_password1 i').addClass( "fa-eye" );
            }
        });
    });
        let intervalo = setInterval(() => {
            this.token = localStorage.getItem('tkn');
            if (this.token !== undefined && this.token !== null && this.token !== '') {
                /* this.loader = true; */
                let f = new Date();
                    let objLog = {
                        'token': this.token,
                        'endpoint': 'init-page',
                        'status': 'init-page',
                        'date': f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear() + ' ' + f.getHours() + ':' + f.getMinutes() + ':' + f.getSeconds(),
                        'uuid': this.uuid
                    };
                    this._loginService.sendLogsLSP(objLog, this.token).subscribe(
                        () => {},
                        error => {
                            console.error('Error logs ' + JSON.stringify(error));
                        }
                    );
                clearInterval(intervalo);
            }
        }, 1000);
    }

    /**
     * Funcion para ver la contraseña
     * @param value valor del checkbox
     */
    public showPassword() {
        if (!this.showpassword) {
            this.showpassword = true;
        } else {
            this.showpassword = false;
        }
    }

    /**
     * Funcion para realizar la modificacion de la contraseña
     */
    public validationPassword(): void {
        if (this.password !== '' && this.password !== undefined && this.repeatpassword !== '' && this.repeatpassword !== undefined) {
            if (this.repeatPassword(this.password, this.repeatpassword)) {
                if (this.checkValidationPassword(this.password)) {
                    if (this.token !== null && this.token !== undefined && this.token !== '') {
                        let obj = {
                            'companyId': 4,
                            'newPassword': this.password,
                            'userToken': this.token
                        };
                        this._loginService.rememberPasswordValidation(obj).subscribe(
                            response => {
                              this.textModal = 'Tu contraseña se ha cambiado correctamente. Ya puedes utilizar tu nueva contraseña para acceder a tu cuenta.';
                              this.textUrl = '/login';
                              $('#exampleModalNotificationRemember').modal('show');
                              localStorage.removeItem('remember');
                              localStorage.removeItem('redirectValue');
                            },
                            error => {
                              if (error.error.code === '403.21') {
                                this.textModal = 'La contraseña que intentas poner ha sido usada previamente. Introduce una contraseña que no hayas utilizado nunca en NAOS Club.';
                                this.textUrl = null;
                                $('#exampleModalNotificationRemember').modal('show');
                              } else {
                                this.textModal = 'No ha sido posible realizar el cambio de contraseña. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                                this.textUrl = null;
                                $('#exampleModalNotificationRemember').modal('show');
                              }

                            }
                        );
                    } else {
                        this.textModal = 'No ha sido posible realizar el cambio de contraseña. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                        this.textUrl = null;
                        $('#exampleModalNotificationRemember').modal('show');
                    }
                } else {
                    this.textModal = 'La contraseña debe tener una longitud entre 8 y 20 caracteres y contener mayúsculas, minúsculas y números.';
                    this.textUrl = null;
                    $('#exampleModalNotificationRemember').modal('show');
                }
            } else {
                this.textModal = 'La contraseña y la confirmación no son iguales';
                this.textUrl = null;
                $('#exampleModalNotificationRemember').modal('show');
            }
        } else {
            this.textModal = 'Por favor, rellena los campos obligatorios para continuar.';
            this.textUrl = null;
            $('#exampleModalNotificationRemember').modal('show');
        }
    }

    /**
     * Funcion para comprobar si las contraseñas son iguales
     * @param passwor1 contraseña 1
     * @param password2 contraseña 2
     */
    public repeatPassword(passwor1: string, password2: string): boolean {
        if (passwor1 === password2) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Validar contraseña entre 8-16 caracteres con mayusculas, minusculas y digitos.
     * @param password contraseña
     */
    public checkValidationPassword(password: string): boolean {
        let regexPassword = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,20}$/g;
        if (regexPassword.test(password)) {
            return true;
        } else {
            return false;
        }
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }
}
