<div class="modal fade" id="exampleModalNotification" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 filaLogo">
                <img src="../../../assets/images/LOGO-NaosClub.png" />
            </div>
          </div><br>
          <div class="row">
              <div class="col-md-12 filaLogo" id="contenidodiv">
                {{ this.msgModal }}
              </div>
            </div>
            <!-- <div class="row" *ngIf="this.linkRegisterToLogin">
                <div class="col-md-12 filaLogo">
                    El email introducido ya existe. Por favor, identifícate <a class="linkpage" (click)="this.goToLogin()">aquí</a> para continuar.
                </div>
              </div> -->
        </div>
        <div class="modal-footer">
          <button (click)="this.redirectConfirm()" type="button" class="btn btn-secondary btnPopUpFormsCerrar" data-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>